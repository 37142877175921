import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseService } from '../services/base.service';

@Component({
  selector: 'app-property-count-card',
  templateUrl: './property-count-card.component.html',
  styleUrls: ['./property-count-card.component.scss']
})
export class PropertyCountCardComponent implements OnInit {
  @Output() searched = new EventEmitter<any>();
  @Output() setState = new EventEmitter<any>();
  @Input() propertyCounts: any;
  @Input() total: any;
  showAllStates = false;
  isMember = false;

  constructor(
    private baseService: BaseService
  ) { }

  ngOnInit(): void {
    const role = localStorage.getItem("role");
    if (role && role.includes("MEMBER")) {
      this.isMember = true;
    }
  }

  showAll() {
    this.showAllStates = !this.showAllStates;
  }

  search(state: any) {
    const params = {
      collection: "memberLocked",
      state: state,
      keywords: null,
      minPrice: null,
      maxPrice: null,
      active: true,
      privateTreaty: false,
      pendingAuction: false,
      soonReauction: false,
      expired: false,
      propertyType: null,
      minArea: null,
      maxArea: null,
      minDate: null,
      maxDate: null,
      auctionType: null,
      bank: null,
    }
    sessionStorage.setItem('searchParams', JSON.stringify(params));
    this.baseService.searchProperties(params).subscribe(o => {
      if (o) {
        this.searched.emit(o);
        this.setState.emit(state);
      }
    });
  }

}
