import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturedCarouselComponent } from './featured-carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatIconModule } from '@angular/material/icon';
import { PropertyCardModule } from '../property-card/property-card.module';

@NgModule({
  declarations: [FeaturedCarouselComponent],
  imports: [
    CommonModule,
    CarouselModule,
    MatIconModule,
    PropertyCardModule
  ],
  exports: [
    FeaturedCarouselComponent
  ]
})
export class FeaturedCarouselModule { }
