import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BaseService } from '../services/base.service';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Observable, merge } from 'rxjs';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { last, map } from 'rxjs/operators';

@Component({
  selector: 'app-search-properties',
  templateUrl: './search-properties.component.html',
  styleUrls: ['./search-properties.component.scss']
})
export class SearchPropertiesComponent implements OnInit {
  @Output() searched = new EventEmitter<any>();
  @Input() total: any;
  advanced = false;
  isMember = false;
  auctionTypeText = "";
  bankText = "";
  propertyTypes: Observable<any>;
  states: Observable<any>;
  auctionTypes: Observable<any>;
  banks: Observable<any>;
  showSeconds = false;
  stepMinute = 1;
  minDefaultTime = [6, 0, 0];
  maxDefaultTime = [23, 59, 59];

  searchPropertiesForm = this.fb.group({
    keywords: this.fb.control(''),
    state: this.fb.control(''),
    minPrice: this.fb.control(''),
    maxPrice: this.fb.control(''),
  });
  advancedSearchForm = this.fb.group({
    active: this.fb.control(true),
    privateTreaty: this.fb.control(false),
    pendingAuction: this.fb.control(false),
    soonReauction: this.fb.control(false),
    expired: this.fb.control(false),
    auctionType: this.fb.control(''),
    propertyType: this.fb.control(''),
    minArea: this.fb.control(''),
    maxArea: this.fb.control(''),
    minDate: this.fb.control(''),
    maxDate: this.fb.control(''),
    bank: this.fb.control(''),
  });

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder,
    private remoteConfig: AngularFireRemoteConfig,
  ) {
    this.propertyTypes = this.remoteConfig.strings.propertyTypes.pipe(
      last(),
      map(o => {
        return JSON.parse(o);
      }),
    );
    this.states = this.remoteConfig.strings.states.pipe(
      last(),
      map(o => {
        return JSON.parse(o);
      }),
    );
    this.auctionTypes = this.remoteConfig.strings.auctionTypes.pipe(
      last(),
      map(o => {
        return JSON.parse(o);
      }),
    );
    this.banks = this.remoteConfig.strings.banks.pipe(
      last(),
      map(o => {
        return JSON.parse(o);
      }),
    );
  }

  ngOnInit(): void {
    var role = localStorage.getItem('role');
    var uid = this.baseService.uid;
    if (uid) {
      this.isMember = true;
      this.auctionTypeText = "Auction Type";
      this.bankText = "Bank";
    } else {
      this.isMember = false;
      this.auctionTypeText = "Auction Type (Member Only)";
      this.bankText = "Bank (Member Only)";
    }    
  }

  onSearch() {
    var params = Object.assign(this.searchPropertiesForm.value, this.advancedSearchForm.value);
    params.collection = 'memberLocked';
    if (params.keywords == '') {
      params.keywords = null;
    }
    if (params.auctionType == '') {
      params.auctionType = null;
    }
    if (params.propertyType == '') {
      params.propertyType = null;
    }
    if (params.state == '') {
      params.state = null;
    }
    if (params.minPrice == '') {
      params.minPrice = null;
    } else {
      params.priceQuery = true
    }
    if (params.maxPrice == '') {
      params.maxPrice = null;
    } else {
      params.priceQuery = true
    }
    if (params.minArea == '') {
      params.minArea = null;
    } else {
      params.areaQuery = true
    }
    if (params.maxArea == '') {
      params.maxArea = null;
    } else {
      params.areaQuery = true
    }
    if (params.minDate == '') {
      params.minDate = null;
    } else {
      params.dateQuery = true
    }
    if (params.maxDate == '') {
      params.maxDate = null;
    } else {
      params.dateQuery = true
    }
    if (params.bank == '') {
      params.bank = null;
    }
    if (
      params.keywords == null &&
      params.auctionType == null &&
      params.propertyType == null &&
      params.state == null &&
      params.minPrice == null &&
      params.maxPrice == null &&
      params.minArea == null &&
      params.maxArea == null &&
      params.minDate == null &&
      params.maxDate == null &&
      params.active == false &&
      params.privateTreaty == false &&
      params.pendingAuction == false &&
      params.soonReauction == false &&
      params.expired == false &&
      params.bank == false
    ) {
      this.baseService.getLatestProperties().subscribe(o => {
        if (o) {
          this.searched.emit(o);
        }
      });
    } else {
      if (params.keywords) {
        params.keywords = params.keywords.toLowerCase().split(" ");
      }
      if (params.minDate) {
        params.minDate = this.baseService.removeTime(params.minDate.getTime());
      }
      if (params.maxDate) {
        params.maxDate = params.maxDate.getTime();
      }
      // this.baseService.testSearch(params).subscribe(o => {
      //   if (o) {
      //     this.searched.emit(o);
      //   }
      // });
      sessionStorage.setItem('searchParams', JSON.stringify(params));
      const sub = this.baseService.searchProperties(params).subscribe(o => {
        if (o) {
          var result = o;
          if (params.minDate || params.maxDate) {
            if (params.minPrice) {
              var tempArray = [];
              result.forEach(element => {
                if (element.reservePrice >= params.minPrice) {
                  tempArray.push(element);
                }
              });
              result = tempArray;
            }
            if (params.maxPrice) {
              var tempArray = [];
              result.forEach(element => {
                if (element.reservePrice <= params.maxPrice) {
                  tempArray.push(element);
                }
              });
              result = tempArray;
            }
            if (params.minArea) {
              var tempArray = [];
              result.forEach(element => {
                if (element.landArea >= params.minArea) {
                  tempArray.push(element);
                }
              });
              result = tempArray;
            }
            if (params.maxArea) {
              var tempArray = [];
              result.forEach(element => {
                if (element.landArea <= params.maxArea) {
                  tempArray.push(element);
                }
              });
              result = tempArray;
            }
            if (params.active == false && params.privateTreaty == false && params.pendingAuction == false && params.soonReauction == false && params.active == false) {
              var tempArray = [];
              result.forEach(element => {
                if (element.status != 'Expired') {
                  tempArray.push(element);
                }
              });
              result = tempArray;
            }
          } else if (params.minPrice || params.maxPrice) {
            if (params.minDate) {
              var tempArray = [];
              result.forEach(element => {
                if (params.collection = 'properties') {
                  if (element.auctionDate >= params.minDate) {
                    tempArray.push(element);
                  }
                } else if (params.collection = 'memberLocked') {
                  if (element.auctionTime >= params.minDate) {
                    tempArray.push(element);
                  }
                }
              });
              result = tempArray;
            }
            if (params.maxDate) {
              var tempArray = [];
              result.forEach(element => {
                if (params.collection = 'properties') {
                  if (element.auctionDate <= params.maxDate) {
                    tempArray.push(element);
                  }
                } else if (params.collection = 'memberLocked') {
                  if (element.auctionTime <= params.maxDate) {
                    tempArray.push(element);
                  }
                }
              });
              result = tempArray;
            }
            if (params.minArea) {
              var tempArray = [];
              result.forEach(element => {
                if (element.landArea >= params.minArea) {
                  tempArray.push(element);
                }
              });
              result = tempArray;
            }
            if (params.maxArea) {
              var tempArray = [];
              result.forEach(element => {
                if (element.landArea <= params.maxArea) {
                  tempArray.push(element);
                }
              });
              result = tempArray;
            }
            if (params.active == false && params.privateTreaty == false && params.pendingAuction == false && params.soonReauction == false && params.active == false) {
              var tempArray = [];
              result.forEach(element => {
                if (element.status != 'Expired') {
                  tempArray.push(element);
                }
              });
              result = tempArray;
            }
          } else if (params.minArea || params.maxArea) {
            if (params.minDate) {
              var tempArray = [];
              result.forEach(element => {
                if (params.collection = 'properties') {
                  if (element.auctionDate >= params.minDate) {
                    tempArray.push(element);
                  }
                } else if (params.collection = 'memberLocked') {
                  if (element.auctionTime >= params.minDate) {
                    tempArray.push(element);
                  }
                }
              });
              result = tempArray;
            }
            if (params.maxDate) {
              var tempArray = [];
              result.forEach(element => {
                if (params.collection = 'properties') {
                  if (element.auctionDate <= params.maxDate) {
                    tempArray.push(element);
                  }
                } else if (params.collection = 'memberLocked') {
                  if (element.auctionTime <= params.maxDate) {
                    tempArray.push(element);
                  }
                }
              });
              result = tempArray;
            }
            if (params.minPrice) {
              var tempArray = [];
              result.forEach(element => {
                if (element.reservePrice >= params.minPrice) {
                  tempArray.push(element);
                }
              });
              result = tempArray;
            }
            if (params.maxPrice) {
              var tempArray = [];
              result.forEach(element => {
                if (element.reservePrice <= params.maxPrice) {
                  tempArray.push(element);
                }
              });
              result = tempArray;
            }
            if (params.active == false && params.privateTreaty == false && params.pendingAuction == false && params.soonReauction == false && params.active == false) {
              var tempArray = [];
              result.forEach(element => {
                if (element.status != 'Expired') {
                  tempArray.push(element);
                }
              });
              result = tempArray;
            }
          }
          this.searched.emit(result);
        }
      });
    }
  }

  showAdvanced() {
    this.advanced = !this.advanced;
  }

  clearSearch() {
    sessionStorage.removeItem('searchParams');
    sessionStorage.removeItem('pageNumber');
    window.location.reload();
  }

  @Input() set state(state: string) {
    const searchFormParams = {
      keywords: "",
      state: state,
      minPrice: "",
      maxPrice: "",
    }
    const advancedSearchParams = {
      active: true,
      privateTreaty: false,
      pendingAuction: false,
      soonReauction: false,
      expired: false,
      auctionType: "",
      propertyType: "",
      minArea: "",
      maxArea: "",
      minDate: "",
      maxDate: "",
      bank: "",
    }
    this.searchPropertiesForm.setValue(searchFormParams);
    this.advancedSearchForm.setValue(advancedSearchParams);
  }

  rangeQueryDecision(params: any) {
    const dateMean = 42;
    var minDate = 0;
    var maxDate = 99999999999999999999999999999;

    const priceMean = 654250;
    const priceSD = 6221215;
    var minPrice = 0;
    var maxPrice = 99999999999;

    const areaMean = 14220;
    const areaSD = 354222;
    var minArea = 0;
    var maxArea = 99999999999;

    if (params.minDate) {
      minDate = params.minDate;
    }
    if (params.maxDate) {
      maxDate = params.maxDate;
    }
    if (params.minPrice) {
      minPrice = params.minPrice;
    }
    if (params.maxPrice) {
      maxPrice = params.maxPrice;
    }
    if (params.minArea) {
      minArea = params.minArea;
    }
    if (params.maxArea) {
      maxArea = params.maxArea;
    }

    var estDateUnits = ((maxDate - minDate) / 86400000) * dateMean;
    var estPriceUnits: number;
    var estAreaUnits: number;

    var minPriceArea = 0;
    if (minPrice < priceMean) {
      const sd = (priceMean - minPrice) / priceSD;
      minPriceArea = this.getArea(sd);
    } else {
      const sd = (minPrice - priceMean) / priceSD;
      minPriceArea = 0.5 - this.getArea(sd);
    }
    var maxPriceArea = 0;
    if (maxPrice > priceMean) {
      const sd = (maxPrice - priceMean) / priceSD;
      maxPriceArea = this.getArea(sd);
    } else {
      const sd = (priceMean - maxPrice) / priceSD;
      maxPriceArea = 0.5 - this.getArea(sd);
    }
    estPriceUnits = (minPriceArea + maxPriceArea) * this.total;

    var minAreaArea = 0;
    if (minArea < areaMean) {
      const sd = (areaMean - minArea) / areaSD;
      minAreaArea = this.getArea(sd);
    } else {
      const sd = (minArea - areaMean) / areaSD;
      minAreaArea = 0.5 - this.getArea(sd);
    }
    var maxAreaArea = 0;
    if (maxArea > areaMean) {
      const sd = (maxArea - areaMean) / areaSD;
      maxAreaArea = this.getArea(sd);
    } else {
      const sd = (areaMean - maxArea) / areaSD;
      maxAreaArea = 0.5 - this.getArea(sd);
    }
    estAreaUnits = (minAreaArea + maxAreaArea) * this.total;

    console.log('Date: ' + estDateUnits);
    console.log(minPriceArea);
    console.log(maxPriceArea);
    console.log('Price: ' + estPriceUnits);
    console.log('Area: ' + estAreaUnits);
  }

  getArea(zScore: any) {
    const num = zScore.toFixed(1);
    const zTable = {
      "0.0": 0,
      "0.1": 0.0398,
      "0.2": 0.0793,
      "0.3": 0.1179,
      "0.4": 0.1554,
      "0.5": 0.1915,
      "0.6": 0.2257,
      "0.7": 0.2580,
      "0.8": 0.2881,
      "0.9": 0.3159,
      "1.0": 0.3413,
      "1.1": 0.3643,
      "1.2": 0.3849,
      "1.3": 0.4032,
      "1.4": 0.4192,
      "1.5": 0.4332,
      "1.6": 0.4452,
      "1.7": 0.4554,
      "1.8": 0.4641,
      "1.9": 0.4713,
      "2.0": 0.4772,
      "2.1": 0.4821,
      "2.2": 0.4861,
      "2.3": 0.4893,
      "2.4": 0.4918,
      "2.5": 0.4938,
      "2.6": 0.4953,
      "2.7": 0.4965,
      "2.8": 0.4974,
      "2.9": 0.4981,
      "3.0": 0.4987,
      "3.1": 0.4990,
      "3.2": 0.4993,
      "3.3": 0.4995,
      "3.4": 0.4997,
      "3.5": 0.4998,
      "3.6": 0.4999,
    }
    const area = zTable[num.toString()];
    if (area) {
      return area;
    } else {
      return 0.5;
    }
  }

  getStandardDeviation(o) {
    //population
    const population = o.length;
    console.log('N: ' + population);
    //mean
    var total = 0;
    o.forEach(element => {
      total = total + element.reservePrice;
    });
    const mean = total / population;
    console.log('m: ' + mean);
    //standard deviation
    var sum = 0;
    o.forEach(element => {
      sum = sum + Math.pow(element.reservePrice - mean, 2)
    });
    console.log('sum: ' + sum);
    const sd = Math.sqrt(sum / population);
    console.log('sd: ' + sd);
  }

}
