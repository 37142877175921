import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-banner-carousel',
  templateUrl: './banner-carousel.component.html',
  styleUrls: ['./banner-carousel.component.scss']
})
export class BannerCarouselComponent implements OnInit {
  @ViewChild('mainCarousel') mainCarousel: CarouselComponent;
  
  banners = [
    {href: "https://homeland.my/", src: "https://urbanparadigm.github.io/storage/homebid-main-poster-1.png"},
    {href: "#/private-auction-sell/1", src: "https://urbanparadigm.github.io/storage/homebid-main-poster-2.png"},
  ]
  mainOptions: OwlOptions = {
    responsive: {
      0: {
        items: 1
      }
    },
    autoHeight: true,
    center: false,
    stagePadding: 0,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 4,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: false
  };
  
  constructor() { }

  ngOnInit(): void {
  }

  next() {
    this.mainCarousel.next();
  }

  previous() {
    this.mainCarousel.prev();
  }

}
