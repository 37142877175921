import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturedPropertiesComponent } from './featured-properties.component';
import { PropertyCardModule } from '../property-card/property-card.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { ContactCardModule } from '../contact-card/contact-card.module';
import { FeaturedCarouselModule } from '../featured-carousel/featured-carousel.module';
import { MatCardModule } from '@angular/material/card';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [FeaturedPropertiesComponent],
  imports: [
    CommonModule,
    PropertyCardModule,
    ContactCardModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    FormsModule,
    FeaturedCarouselModule,
    NgbCarouselModule,
  ],
  exports: [
    FeaturedPropertiesComponent,
  ]
})
export class FeaturedPropertiesModule { }
