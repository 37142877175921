<mat-card class="mat-elevation-z8">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 px-3">
                <div class="mat-h1">
                    Enquiry
                </div>
            </div>
            <div class="col-12 pb-3">
                <mat-card>
                    <form id="sellingEnquiry" [formGroup]="sellingEnquiryForm">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <h2>Personal Details</h2>
                                </div>
                                <div class="col-12 col-md-6">
                                    <mat-form-field color="accent">
                                        <mat-label>Full name</mat-label>
                                        <input formControlName="fullName" matInput />
                                        <mat-error>Please fill in</mat-error>
                                    </mat-form-field>
                                    <mat-form-field color="accent">
                                        <mat-label>NRIC number</mat-label>
                                        <input formControlName="icNumber" matInput type="number" />
                                        <mat-error>Please fill in</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-md-6">
                                    <mat-form-field color="accent">
                                        <mat-label>E-mail</mat-label>
                                        <input formControlName="email" matInput type="email" email />
                                        <mat-error>Please fill in</mat-error>
                                    </mat-form-field>
                                    <mat-form-field color="accent">
                                        <mat-label>Contact number</mat-label>
                                        <input formControlName="contactNumber" matInput />
                                        <mat-error>Please fill in</mat-error>
                                    </mat-form-field>
                                </div>
                                
                                <div class="col-12">
                                    <h2>Property Details</h2>
                                </div>
                                <div class="col-12 col-md-6">
                                    <mat-form-field color="accent">
                                        <mat-label>*Property name</mat-label>
                                        <input formControlName="propertyName" matInput />
                                        <mat-error>Please fill in</mat-error>
                                    </mat-form-field>
                                    <ng-container *ngIf="propertyTypes | async as propertyTypes">
                                        <mat-form-field appearance="fill">
                                            <mat-label>*Property type</mat-label>
                                            <mat-select formControlName="propertyType">
                                                <mat-option *ngFor="let propertyType of propertyTypes"
                                                    [value]="propertyType">
                                                    {{propertyType}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <mat-form-field color="accent">
                                        <mat-label>*Reserve price (RM)</mat-label>
                                        <input formControlName="reservePrice" matInput type="number" min="0"/>
                                        <mat-error>Please fill in</mat-error>
                                    </mat-form-field>
                                    <mat-form-field color="accent">
                                        <mat-label>*Area size (sqft.)</mat-label>
                                        <input formControlName="landArea" matInput type="number" min="0"/>
                                        <mat-error>Please fill in</mat-error>
                                    </mat-form-field>
                                    <mat-form-field color="accent">
                                        <mat-label>*Address</mat-label>
                                        <input formControlName="address" matInput />
                                        <mat-error>Please fill in</mat-error>
                                    </mat-form-field>
                                    <ng-container *ngIf="states | async as states">
                                        <mat-form-field appearance="fill">
                                            <mat-label>*State</mat-label>
                                            <mat-select formControlName="state">
                                                <mat-option *ngFor="let state of states" [value]="state">
                                                    {{state}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="tenures | async as tenures">
                                        <mat-form-field appearance="fill">
                                            <mat-label>*Tenure</mat-label>
                                            <mat-select formControlName="tenure">
                                                <mat-option *ngFor="let tenure of tenures" [value]="tenure">
                                                    {{tenure}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                </div>
                                <div class="col-12 col-md-6">
                                    <mat-form-field color="accent">
                                        <mat-label>Number of bedroom(s)</mat-label>
                                        <input formControlName="bedroom" matInput type="number" min="0"/>
                                    </mat-form-field>
                                    <mat-form-field color="accent">
                                        <mat-label>Number of bathroom(s)</mat-label>
                                        <input formControlName="bathroom" matInput type="number" min="0"/>
                                    </mat-form-field>
                                    <mat-form-field color="accent">
                                        <mat-label>Number of car park(s)</mat-label>
                                        <input formControlName="carPark" matInput type="number" min="0"/>
                                    </mat-form-field>
                                    <mat-form-field color="accent">
                                        <mat-label>Number of storey(s)</mat-label>
                                        <input formControlName="storey" matInput type="number" min="0"/>
                                    </mat-form-field>
                                    <ng-container *ngIf="ownerRaces | async as ownerRaces">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Owner Race</mat-label>
                                            <mat-select formControlName="ownerRace">
                                                <mat-option *ngFor="let ownerRace of ownerRaces" [value]="ownerRace">
                                                    {{ownerRace}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="restrictions | async as restrictions">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Restriction</mat-label>
                                            <mat-select formControlName="restriction">
                                                <mat-option *ngFor="let restriction of restrictions" [value]="restriction">
                                                    {{restriction}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="lotTypes | async as lotTypes">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Lot type</mat-label>
                                            <mat-select formControlName="lotType">
                                                <mat-option *ngFor="let lotType of lotTypes" [value]="lotType">
                                                    {{lotType}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-card>
            </div>
            <div class="col-12 pb-3">
                <mat-card>
                    <p class="mat-h2">*Upload property image</p>
                    <input type="file" (change)="setImage($event)" accept="image/*">
                    <img class="imgUpload my-2" [src]="imgURL">
                </mat-card>
            </div>
            <div class="col-12">
                <div class="d-flex flex-row-reverse">
                    <button mat-raised-button color="accent" (click)="submitEnquiry()" form="sellingEnquiry">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-card>