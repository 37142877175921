import { Component, OnInit } from '@angular/core';
import { LoginComponent } from './../login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { BaseService } from '../services/base.service';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { NewsletterFormComponent } from '../newsletter-form/newsletter-form.component';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
  timedOutCloser;
  loggedIn = false;
  username = "";
  uid = null;
  role = "";
  isAdmin = false;
  isAuctioneer = false;
  isAgent = false;
  isMember = false;
  handset = null;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private baseService: BaseService,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.uid = localStorage.getItem('uid');
    if (this.uid) {
      this.loggedIn = true;
      this.username = localStorage.getItem('username');
      this.role = localStorage.getItem('role');
      if (this.role.includes('ADMIN')) {
        this.isAdmin = true;
      }
      if (this.role.includes('AUCTIONEER')) {
        this.isAuctioneer = true;
      }
      if (this.role.includes('AGENT')) {
        this.isAgent = true;
      }
      if (this.role.includes('MEMBER')) {
        this.isMember = true;
      }
    } else {
      this.loggedIn = false;
    }

    this.subscriptions.push(this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      if (result.matches) {
        this.handset = true;
      }
    }));
    this.subscriptions.push(this.breakpointObserver.observe([
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.matches) {
        this.handset = false;
      }
    }));
  }

  // showSignUpDialog() {
  //   this.dialog.open(SignUpComponent, {
  //     width: '400px'
  //   });
  // }

  showLoginDialog() {
    this.dialog.open(LoginComponent, {
      width: '400px'
    });
  }

  onLogOut() {
    this.baseService.signOut();
  }

  redirect(url: string) {
    window.location.href = url;
  }

  showNewsletter() {
    this.dialog.open(NewsletterFormComponent, {
      width: '400px'
    });
  }

  shareContact(platform: string) {
    const shareLink = 'https://auction-directory.web.app/shareContact?username=' + this.username;
    if (platform == 'whatsapp') {
      window.open('https://api.whatsapp.com/send?text=' + shareLink);
    } else if (platform == 'facebook') {
      window.open('https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=' + shareLink + '&display=popup&ref=plugin&src=share_button');
    }
  }

  clearSearch() {
    sessionStorage.removeItem('searchParams');
    if (this.router.url === "/") {
      window.location.reload();
    }
  }

  //quite a temporary solution
  getPrivateBidding() {
    const params = {
      collection: "memberLocked",
      state: null,
      keywords: null,
      minPrice: null,
      maxPrice: null,
      active: false,
      privateTreaty: true,
      pendingAuction: false,
      soonReauction: false,
      expired: false,
      propertyType: "Private Bidding",
      minArea: null,
      maxArea: null,
      minDate: null,
      maxDate: null,
      auctionType: null,
      bank: null,
    }
    sessionStorage.setItem('searchParams', JSON.stringify(params));
    window.location.reload();
  }

  search(propertyType: string) {
    this.router.navigateByUrl("/property-search/" + propertyType);
  }
}
