import { Component, Input, OnInit } from '@angular/core';
import { BaseService } from '../services/base.service';
import { faWhatsapp, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {
  agentDetails: any;
  imgURL: string;
  disabled = true;
  shareLink = "";
  faWhatsapp = faWhatsapp;
  faFacebookMessenger = faFacebookMessenger;

  constructor(
    private baseService: BaseService
  ) { }

  ngOnInit(): void {
    var username = localStorage.getItem("username");
    if (username) {
      this.shareLink = 'https://auction-directory.web.app/shareContact?username=' + username;
    }    
  }

  copied() {
    window.alert("URL copied to clipboard, paste to Whatsapp or Facebook for contact sharing");
  }

  @Input() set agentName(name) {
    if (name) {
      this.baseService.getUserList(name, false).subscribe(o => {
        if (!o.disabled && (o.role.includes("AGENT") || o.role.includes("AUCTIONEER"))) {
          this.disabled = false;
          this.agentDetails = o;
          this.cardDesign = o.cardPattern;
          this.shareLink = 'https://auction-directory.web.app/shareContact?username=' + name;
          const sub = this.baseService.getUserStorage(name).subscribe(o => {
            sub.unsubscribe();
            this.imgURL = o;
          });
        } else {
          this.disabled = true;
        }
      })
    }
  };

  @Input() set cardDesign(pattern) {
    this.agentDetails.cardPattern = pattern;
  }
}
