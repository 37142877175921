<form id="newsletter" [formGroup]="newsletterForm">
    <div class="d-flex flex-column">
        <input class="form-field mb-2" formControlName="name" placeholder="Name" />
        <input class="form-field mb-2" formControlName="phoneNumber" placeholder="Contact Number" />
        <input class="form-field mb-2" formControlName="email" type="email" email placeholder="E-mail" />
        <!-- <mat-form-field color="accent">
            <mat-label>Name</mat-label>
            <input formControlName="name" matInput />
            <mat-error>Please fill in</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Phone number</mat-label>
            <input formControlName="phoneNumber" matInput />
            <mat-error>Please fill in</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>E-mail</mat-label>
            <input formControlName="email" matInput type="email" email />
            <mat-error>Invalid E-mail</mat-error>
        </mat-form-field> -->
        <!-- <ng-container *ngIf="states | async as states">
            <mat-form-field color="accent">
                <mat-label>Interested state</mat-label>
                <mat-select formControlName="state">
                    <mat-option *ngFor="let state of states" [value]="state">
                        {{state}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container> -->
    </div>
</form>
<div class="d-flex justify-content-end">
    <button id="subscribe-btn" (click)="onSubscribe()" form="newsletter">
        Subscribe
    </button>
</div>