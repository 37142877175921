<div class="row text-center">
    <div class="col-12 d-flex justify-content-between">
        <div class="clickable" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">
            <mat-icon>chevron_left</mat-icon>
        </div>
        <div class="mat-h2 m-0 clickable" mwlCalendarToday [(viewDate)]="viewDate">{{ viewDate | calendarDate:(view +
            'ViewTitle'):'en' }}</div>
        <div class="clickable" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">
            <mat-icon>chevron_right</mat-icon>
        </div>

    </div>
</div>
<br />
<div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
        [cellTemplate]="customCellTemplate">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-day-view>
</div>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top" [ngClass]="day.badgeTotal === 0? '' : 'hasEvent'">
        <!-- <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span> -->
        <span *ngIf="day.badgeTotal === 0; else hasEvent" class="cal-day-number bold-text">{{ day.date |
            calendarDate:'monthViewDayNumber':locale }}</span>
        <ng-template #hasEvent>
            <span class="cal-day-number bold-text">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
        </ng-template>
    </div>
</ng-template>