<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <mat-form-field color="accent">
                <mat-label>Type</mat-label>
                <mat-select value="PHOTO" (selectionChange)="typeChanged($event.value)">
                    <mat-option value="PHOTO">Photos</mat-option>
                    <mat-option value="VIDEO">Videos</mat-option>
                    <mat-option value="NEWS">News</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12">
            <ng-container *ngIf="type === 'PHOTO'">
                <mat-card>
                    <form id="photo" [formGroup]="photoForm">
                        <div class="d-flex flex-column">
                            <mat-form-field color="accent">
                                <mat-label>Title</mat-label>
                                <input formControlName="title" matInput />
                                <mat-error>Invalid title</mat-error>
                            </mat-form-field>
                            <mat-form-field color="accent">
                                <mat-label>Description</mat-label>
                                <textarea formControlName="description" matInput></textarea>
                                <mat-error>Invalid description</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Date</mat-label>
                                <input formControlName="date" [matDatepicker]="photoPicker" matInput
                                    (click)="photoPicker.open()" readonly>
                                <mat-datepicker-toggle matSuffix [for]="photoPicker"></mat-datepicker-toggle>
                                <mat-datepicker #photoPicker></mat-datepicker>
                                <mat-error>Please pick a time</mat-error>
                            </mat-form-field>
                        </div>
                    </form>

                    <p class="mat-h2">*Upload Thumbnail Image</p>
                    <input type="file" (change)="setImage($event)" accept="image/*">
                    <img class="imgUpload my-2" [src]="imgURL">

                    <div class="row">
                        <div class="col-12">
                            <p class="mat-h2">Additional Images</p>
                        </div>
                        <div class="col-12">
                            <input type="file" multiple (change)="setAdditionalImage($event)" accept="image/*">
                        </div>
                        <div class="col-12 col-md-2" *ngFor="let url of additionalImgURLs">
                            <img class="smallImage my-2" [src]="url">
                        </div>
                    </div>

                    <div class="d-flex justify-content-end">
                        <button mat-raised-button color="accent" (click)="submitPhoto()">Submit</button>
                    </div>
                </mat-card>

                <app-event-photo></app-event-photo>
            </ng-container>
            <ng-container *ngIf="type === 'VIDEO'">
                <mat-card>
                    <form id="video" [formGroup]="videoForm">
                        <div class="d-flex flex-column">
                            <mat-form-field color="accent">
                                <mat-label>Title</mat-label>
                                <input formControlName="title" matInput />
                                <mat-error>Invalid title</mat-error>
                            </mat-form-field>
                            <mat-form-field color="accent">
                                <mat-label>YouTube URL</mat-label>
                                <input formControlName="url" matInput>
                                <mat-error>Invalid url</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Date</mat-label>
                                <input formControlName="date" [matDatepicker]="videoPicker" matInput
                                    (click)="videoPicker.open()" readonly>
                                <mat-datepicker-toggle matSuffix [for]="videoPicker"></mat-datepicker-toggle>
                                <mat-datepicker #videoPicker></mat-datepicker>
                                <mat-error>Please pick a time</mat-error>
                            </mat-form-field>
                        </div>
                    </form>

                    <div class="d-flex justify-content-end">
                        <button mat-raised-button color="accent" (click)="submitVideo()">Submit</button>
                    </div>
                </mat-card>

                <app-event-video></app-event-video>
            </ng-container>
            <ng-container *ngIf="type === 'NEWS'">
                <mat-card>
                    <form id="news" [formGroup]="newsForm">
                        <div class="d-flex flex-column">
                            <mat-form-field color="accent">
                                <mat-label>Title</mat-label>
                                <input formControlName="title" matInput />
                                <mat-error>Invalid title</mat-error>
                            </mat-form-field>
                            <mat-form-field color="accent">
                                <mat-label>News URL</mat-label>
                                <input formControlName="url" matInput>
                                <mat-error>Invalid url</mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Date</mat-label>
                                <input formControlName="date" [matDatepicker]="newsPicker" matInput
                                    (click)="newsPicker.open()" readonly>
                                <mat-datepicker-toggle matSuffix [for]="newsPicker"></mat-datepicker-toggle>
                                <mat-datepicker #newsPicker></mat-datepicker>
                                <mat-error>Please pick a time</mat-error>
                            </mat-form-field>
                        </div>
                    </form>

                    <p class="mat-h2">*Upload Thumbnail Image</p>
                    <input type="file" (change)="setNewsThumbnail($event)" accept="image/*">
                    <img class="imgUpload my-2" [src]="newsThumbnailURL">


                    <div class="d-flex justify-content-end">
                        <button mat-raised-button color="accent" (click)="submitNews()">Submit</button>
                    </div>
                </mat-card>

                <app-event-news></app-event-news>
            </ng-container>
        </div>
    </div>
</div>