import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { endOfDay, startOfDay } from 'date-fns';
import { BaseService } from '../services/base.service';

@Component({
  selector: 'app-calendar-event',
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss']
})
export class CalendarEventComponent implements OnInit {
  @Output() bankSearch = new EventEmitter<any>();
  event: any;
  allBanks: any;

  constructor(
    private baseService: BaseService
  ) { }

  ngOnInit(): void {}

  @Input() set allEvent (event: any) {
    if (event) {      
      this.event = event
    
      var bankMap = {};
      var banks: Set<string> = new Set<string>();
      event.events.forEach(element => {
        banks.add(element.title);
      });
      
      banks.forEach(bank => {
        bankMap[bank] = [];
      });
  
      event.events.forEach(element => {
        bankMap[element.title].push(element);
      });
  
      this.allBanks = bankMap;
    }
  }

  getCalendarProperties(bank: string) {
    const startDate = startOfDay(this.event.date).getTime();
    const endDate = endOfDay(this.event.date).getTime();

    const params = {
      collection: "memberLocked",
      state: null,
      keywords: null,
      minPrice: null,
      maxPrice: null,
      active: !this.event.isPast,
      privateTreaty: false,
      pendingAuction: false,
      soonReauction: false,
      expired: this.event.isPast,
      propertyType: null,
      minArea: null,
      maxArea: null,
      minDate: startDate,
      maxDate: endDate,
      auctionType: null,
      bank: bank,
    }
    
    const sub = this.baseService.searchProperties(params).subscribe(o => {
      sub.unsubscribe();
      if (o) {        
        this.bankSearch.emit(o);
      }
    });
    
    
  }

  getStart(date: number) {
    return startOfDay(date).getTime();
  }

}
