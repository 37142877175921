import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireRemoteConfigModule, SETTINGS as setting } from '@angular/fire/remote-config';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopNavModule } from './top-nav/top-nav.module';
import { LoginModule } from './login/login.module';
import { FooterModule } from './footer/footer.module';
import { FeaturedPropertiesModule } from './featured-properties/featured-properties.module';
import { SearchPropertiesModule } from './search-properties/search-properties.module';
import { HttpClientModule } from '@angular/common/http';
import { PropertyCountCardModule } from './property-count-card/property-count-card.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ContactCardModule } from './contact-card/contact-card.module';
import { LoanCalculatorModule } from './loan-calculator/loan-calculator.module';
import { FeaturedCarouselModule } from './featured-carousel/featured-carousel.module';
import { BuyingFormModule } from './buying-form/buying-form.module';
import { SellingFormModule } from './selling-form/selling-form.module';
import { BannerCarouselModule } from './banner-carousel/banner-carousel.module';
import { NewsletterFormModule } from './newsletter-form/newsletter-form.module';
import { FirstVisitPopupModule } from './first-visit-popup/first-visit-popup.module';
import { BidRegistrationFormModule } from './bid-registration-form/bid-registration-form.module';
import { FormManagementModule } from './form-management/form-management.module';
import { PendingApprovalsModule } from './pending-approvals/pending-approvals.module';
import { AuctionCalendarModule } from './auction-calendar/auction-calendar.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarEventModule } from './calendar-event/calendar-event.module';
import { EventPhotoModule } from './event-photo/event-photo.module';
import { AdminEventsModule } from './admin-events/admin-events.module';
import { EventVideoModule } from './event-video/event-video.module';
import { EventNewsModule } from './event-news/event-news.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireRemoteConfigModule,
    AngularFireFunctionsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    TopNavModule,
    LoginModule,
    FooterModule,
    FeaturedPropertiesModule,
    SearchPropertiesModule,
    HttpClientModule,
    PropertyCountCardModule,
    MatSnackBarModule,
    ContactCardModule,
    LoanCalculatorModule,
    FeaturedCarouselModule,
    BuyingFormModule,
    SellingFormModule,
    BannerCarouselModule,
    NewsletterFormModule,
    FirstVisitPopupModule,
    BidRegistrationFormModule,
    FormManagementModule,
    PendingApprovalsModule,
    AuctionCalendarModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CalendarEventModule,
    EventPhotoModule,
    AdminEventsModule,
    EventVideoModule,
    EventNewsModule,
  ],
  providers: [
    { provide: setting, useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {} },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
