<div class="parent">
    <owl-carousel-o #mainCarousel class="main-carousel" [options]="mainOptions">
        <ng-container *ngFor="let banner of banners; let i = index">
            <ng-template carouselSlide>
                <div class="main-img-container">
                    <a [href]="banner.href"><img [src]="banner.src"></a>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
    <div *ngIf="banners.length > 1" class="left-button d-flex align-items-center justify-content-center"
        (click)="previous()">
        <mat-icon class="arrow clickable">chevron_left</mat-icon>
    </div>
    <div *ngIf="banners.length > 1" class="right-button d-flex align-items-center justify-content-center"
        (click)="next()">
        <mat-icon class="arrow clickable">chevron_right</mat-icon>
    </div>
</div>