<div class="background clickable">
    <!-- <mat-card *ngIf="notFeatured; else isFeatured" class="background bordered clickable"> -->
    <mat-card class="background bordered clickable">
        <div class="imgContainer mb-3">
            <img (click)="navigate()" [ngClass]="noImage? 'noImage' : 'propertyImageHeight'" [src]="imgURL">
            <div class="status" [ngClass]="
            status === 'Called Off'? 'called-off' : 
            status === 'Active'? 'active' :
            status === 'Expired'? 'expired' :
            status === 'Private Treaty'? 'private-treaty' :
            ''">
                {{ status }}
            </div>
        </div>
        <mat-card-content>
            <div class="infoBackground">
                <div class="row">
                    <div class="col-12">
                        <mat-chip-list>
                            <!-- <mat-chip *ngIf="nthAuction">{{ getOrdinal(nthAuction) }} Auction</mat-chip> -->
                            <mat-chip *ngIf="isMember" (click)="toggleFavourite()" matTooltip="Add to Favourite">
                                <mat-icon *ngIf="favourited; else notFavourited">favorite</mat-icon>
                                <ng-template #notFavourited>
                                    <mat-icon>favorite_border</mat-icon>
                                </ng-template>
                            </mat-chip>
                            <mat-chip *ngIf="isAgent || isAdmin" (click)="toggleFeatured()"
                                matTooltip="Add to Featured">
                                <mat-icon *ngIf="featured; else notFeatured">star</mat-icon>
                                <ng-template #notFeatured>
                                    <mat-icon>star_border</mat-icon>
                                </ng-template>
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                    <div (click)="navigate()" class="col-12 pt-3">
                        <div class="row">
                            <div class="col-12">
                                <p class="address bold-text mb-0">
                                    <span *ngIf="isMember">{{ streetNumber }}</span>
                                    {{ route }} {{ city }} {{ postcode }} {{ state }}</p>
                                <p class="mb-3">Built Up: {{ landArea | number }} sqft.</p>
                            </div>
                            <div class="col-12">
                                <p class="m-0 title">Reserve price:</p>
                                <p class="mat-h2">RM {{ reservePrice | number }}</p>
                            </div>
                            <div class="col-12">
                                <p class="m-0 title">Auction Date:</p>
                                <p class="bold-text">{{ auctionDate===4113043200000? 'To be updated': auctionDate | date
                                    }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex">
                                <div *ngIf="auctionType" class="posNType mr-2">
                                    <p class="m-0">{{ auctionType }}</p>
                                </div>
                                <div *ngIf="nthAuction" class="posNType mr-2">
                                    <p class="m-0">
                                        {{ getOrdinal(nthAuction) }} Auction
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="posUUID" class="posNType">
                                <p class="m-0">
                                    <a [href]="pos" target="_blank" style="color: inherit;">POS</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #isFeatured>
    <mat-card class="background bordered clickable p-0">
        <div class="imgContainer">
            <img (click)="navigate()" [ngClass]="noImage? 'noImage' : 'propertyImageHeight'" [src]="imgURL">
            <div class="status" [ngClass]="
            status === 'Called Off'? 'called-off' : 
            status === 'Active'? 'active' :
            status === 'Expired'? 'expired' :
            status === 'Private Treaty'? 'private-treaty' :
            ''">
                {{ status }}
            </div>
            <div *ngIf="nthAuction" class="nthAuction">
                <span class="bold-text">{{nthAuction}}<sup>{{ nthAuction === '1'? 'st' : nthAuction === '2'? 'nd' : 'th'
                        }}</sup></span> Auction
            </div>
        </div>
        <mat-card-header (click)="navigate()">
            <mat-card-title>
                <p class="address bold-text mt-3">
                    <span *ngIf="isMember">{{ streetNumber }}</span>
                    {{ route }} {{ city }} {{ postcode }} {{ state }}
                </p>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="infoBackground px-3 pb-3 pt-0">
                <div class="row">
                    <div (click)="navigate()" class="col-12">
                        <h3>{{ landArea | number }} sqft.</h3>
                        <h3 style="font-weight: bold;">RM {{ reservePrice | number }}</h3>
                    </div>
                    <div class="col-12">
                        <mat-chip-list>
                            <mat-chip *ngIf="nthAuction">{{ getOrdinal(nthAuction) }} Auction</mat-chip>
                            <mat-chip *ngIf="isMember" (click)="toggleFavourite()" matTooltip="Add to Favourite">
                                <mat-icon *ngIf="favourited; else notFavourited">favorite</mat-icon>
                                <ng-template #notFavourited>
                                    <mat-icon>favorite_border</mat-icon>
                                </ng-template>
                            </mat-chip>
                            <mat-chip *ngIf="isAgent || isAdmin" (click)="toggleFeatured()"
                                matTooltip="Add to Featured">
                                <mat-icon *ngIf="featured; else notFeatured">star</mat-icon>
                                <ng-template #notFeatured>
                                    <mat-icon>star_border</mat-icon>
                                </ng-template>
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>