<mat-horizontal-stepper linear="false" [selectedIndex]="selectedIndex" (selectionChange)="selectionChange($event)" #stepper>
    <mat-step label="Default Forms">
        <ng-container *ngFor="let path of paths">
            <mat-card class="my-2" *ngIf="path.show">
                <div class="d-flex justify-content-between">
                    <div>
                        <h2 class="mr-auto my-0">{{ path.formName }}</h2>
                        <p class="mb-0 form-description">{{path.description}}</p>
                    </div>
                    <div class="d-flex align-items-center">
                        <a mat-raised-button target="_blank" color="accent"a [href]="path.fullPath">Download</a>
                        <button class="ml-1" *ngIf="isAdmin" mat-raised-button color="warn"
                            (click)="deleteForm(path.shortPath)">Delete</button>
                    </div>
                </div>
            </mat-card>
        </ng-container>
        <div class="d-flex justify-content-end mr-3">
            <button mat-raised-button color="accent" matStepperNext>Next</button>
        </div>
    </mat-step>

    <mat-step label="Upload Documents">
        <form id="buyer" [formGroup]="buyerForm">
            <div class="container-fluid">
                <div class="row d-flex align-items-center">
                    <div class="col-12 d-flex justify-content-between">
                        <div>
                            <mat-form-field *ngIf="isAdmin" class="mr-3" color="accent">
                                <mat-label>Agent name</mat-label>
                                <input formControlName="buyerAgentName" matInput />
                            </mat-form-field>
                            <mat-form-field class="mr-3" color="accent">
                                <mat-label>Name</mat-label>
                                <input formControlName="buyerName" matInput />
                                <mat-error>Invalid buyer name</mat-error>
                            </mat-form-field>
                            <mat-form-field class="mr-3" color="accent">
                                <mat-label>Property ID</mat-label>
                                <input formControlName="propertyID" matInput />
                                <mat-error>Invalid property ID</mat-error>
                            </mat-form-field>
                            <mat-form-field color="accent">
                                <mat-label>Role</mat-label>
                                <mat-select value="individual" (selectionChange)="roleChanged($event.value)">
                                    <mat-option value="individual">Individual</mat-option>
                                    <mat-option value="company">Company</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="showCreate">
                            <button mat-raised-button color="accent" (click)="openCase('NEW')">Create</button>
                        </div>
    
                    </div>
                </div>
            </div>
        </form>
    
        <div *ngIf="buyerFileOpened" class="container-fluid">
            <div class="row">
                <div class="col-12 d-flex justify-content-end mb-2">
                    <button mat-raised-button (click)="saveAsDraft()">Save as draft</button>
                </div>
                <div *ngFor="let doc of requiredDocs" class="col-12 mb-2">
                    <mat-card>
                        <div class="d-flex justify-content-between">
                            <div>
                                <h2 class="my-0">{{ doc.name }}</h2>
                                <p class="mb-0 form-description">{{ doc.description }}</p>
                            </div>
                            <div class="d-flex align-items-center">
                                <input type="file" class="d-none" (change)="updateBuyerForm(doc.value, $event)"
                                    accept="application/pdf" #uploadDoc>
                                <a *ngIf="doc.url" mat-raised-button class="mr-1" [href]="doc.url" target="_blank">View</a>
                                <button *ngIf="canSubmit" mat-raised-button color="accent"
                                    (click)="uploadDoc.click()">Upload</button>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button mat-raised-button color="accent" matStepperNext>Next</button>
            </div>
        </div>
        
    </mat-step>

    <mat-step label="Submission">
        <ng-container *ngIf="canSubmit && !isAdmin; else cannotSubmit">
            <div class="col-12 mt-3">
                <mat-card class="mat-elevation-z8">
                    <p>
                        *Submission of cases is not easily reversible, please make sure that all documents are
                        properly uploaded before submitting
                    </p>
                    <p>
                        After submitting, you may check your submission progress under the 4<sup>th</sup> step
                    </p>
                    <p>
                        If you have any enquiries, you may contact our customer service at 017 405 9268
                    </p>
                    <div class="col-12 d-flex justify-content-end">
                        <!-- <div class="mr-1">
                            <button mat-raised-button (click)="saveAsDraft()">Save as draft</button>
                        </div> -->
                        <div>
                            <button mat-raised-button (click)="submitCase()" color="accent" matStepperNext>Submit case</button>
                        </div>
                    </div>
                </mat-card>
            </div>
        </ng-container>

        <ng-template #cannotSubmit>
            <div class="col-12 mt-3">
                <mat-card class="mat-elevation-z8">
                    <p class="mat-h2">
                        There are no case to submit, please upload the required documents before submitting, thank you
                    </p>
                    <div class="d-flex justify-content-end">
                        <button mat-raised-button matStepperPrevious>Back</button>
                    </div>
                </mat-card>
            </div>
        </ng-template>

        <ng-container *ngIf="!canSubmit && !isApproved && isAdmin">
            <div class="col-12 mt-3">
                <mat-card class="mat-elevation-z8">
                    <form [formGroup]="approvalForm">
                        <mat-form-field class="full-width">
                            <mat-label>Auction Date</mat-label>
                            <input formControlName="auctionDate" matInput />
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <mat-label>Venue</mat-label>
                            <input formControlName="auctionVenue" matInput />
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <mat-label>Remark</mat-label>
                            <input formControlName="remark" matInput />
                        </mat-form-field>
                    </form>
                    <div class="d-flex justify-content-end">
                        <div class="mr-1">
                            <button mat-raised-button (click)="rejectCase()" color="warn">Reject</button>
                        </div>
                        <div>
                            <button mat-raised-button (click)="approveCase()" color="accent">Approve</button>
                        </div>
                    </div>
                </mat-card>
            </div>
        </ng-container>

        <ng-container *ngIf="!canSubmit && isApproved && isAdmin">
            <div class="col-12 mt-3">
                <mat-card class="mat-elevation-z8">
                    <div class="d-flex justify-content-end">
                        <div class="mr-1">
                            <button mat-raised-button (click)="successfulCase()" color="accent">Successful</button>
                        </div>
                        <div class="mr-1">
                            <button mat-raised-button (click)="unsuccessfulCase()"
                                color="accent">Unsuccessful</button>
                        </div>
                        <div>
                            <button mat-raised-button (click)="calledOff()" color="warn">Called off</button>
                        </div>
                    </div>
                </mat-card>
            </div>
        </ng-container>
    </mat-step>

    <mat-step label="Track Progress">
        <div class="d-flex justify-content-start">
            <mat-form-field class="mr-3" color="accent">
                <mat-label>Submission status</mat-label>
                <mat-select (selectionChange)="searchByStatus($event.value)" [(ngModel)]="statusSearch">
                    <mat-option *ngFor="let status of statuses" [value]="status.value">
                        {{status.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="isAdmin">
                <mat-label>Agent</mat-label>
                <input matInput [(ngModel)]="agentSearch" (input)="searchByAgent()" />
            </mat-form-field>
        </div>
        <ng-container *ngTemplateOutlet="tables"></ng-container>
    </mat-step>
</mat-horizontal-stepper>

<mat-card *ngIf="isAdmin" class="mb-3">
    <h2>Upload default</h2>
    <form id="defaultUpload" [formGroup]="defaultUploadForm">
        <div class="container-fluid">
            <div class="row d-flex align-items-center">
                <div class="col-12 col-md-4">
                    <mat-form-field color="accent">
                        <mat-label>Form name</mat-label>
                        <input formControlName="formName" matInput />
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <input type="file" (change)="defaultUploaded($event)" accept="application/pdf" #defaultForm>
                </div>
                <div class="col-12 col-md-4">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 col-lg-4 py-1">
                                <button mat-raised-button color="accent"
                                    (click)="uploadDefault(defaultForm)">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-card>

<ng-template #tables>
    <table *ngIf="statusSearch === 'ENQUIRIES' || statusSearch === 'REQUESTS' else normal">
        <tr>
            <th>Full name</th>
            <th>IC number</th>
            <th>Property ID</th>
            <th>E-mail</th>
            <th>Contact number</th>
            <th>Subject</th>
            <th>Submit</th>
        </tr>
        <tr *ngFor="let result of searchResults" class="clickable">
            <td (click)="openCase('CONVERT', result)">{{ result.fullName }}</td>
            <td (click)="openCase('CONVERT', result)">{{ result.icNumber }}</td>
            <td><a [href]="'#/property-detail/' + result.propertyID">{{ result.propertyID }}</a></td>
            <td (click)="openCase('CONVERT', result)">{{ result.email }}</td>
            <td (click)="openCase('CONVERT', result)">{{ result.contactNumber }}</td>
            <td (click)="openCase('CONVERT', result)">{{ result.subject }}</td>
            <td (click)="openCase('CONVERT', result)">{{ result.submitted | date: 'medium' }}</td>
        </tr>
    </table>
    <ng-template #normal>
        <table>
            <tr>
                <th>Agent</th>
                <th>Buyer</th>
                <th>Role</th>
                <th>Property ID</th>
                <th>Status</th>
                <th>Remark</th>
                <th>Updated</th>
            </tr>
            <tr *ngFor="let result of searchResults" class="clickable">
                <td (click)="openCase('EXIST', result)">{{ result.agent }}</td>
                <td (click)="openCase('EXIST', result)">{{ result.buyerName }}</td>
                <td (click)="openCase('EXIST', result)">{{ result.role }}</td>
                <td><a [href]="'#/property-detail/' + result.propertyID">{{ result.propertyID }}</a></td>
                <td (click)="openCase('EXIST', result)">{{ getStatusName(result.status) }}</td>
                <td (click)="openCase('EXIST', result)">{{ result.remark }}</td>
                <td (click)="openCase('EXIST', result)">{{ result.updated | date: 'medium' }}</td>
            </tr>
        </table>
    </ng-template>
</ng-template>