import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-loan-calculator',
  templateUrl: './loan-calculator.component.html',
  styleUrls: ['./loan-calculator.component.scss']
})
export class LoanCalculatorComponent implements OnInit {
  monthlyPayment = "0";
  loanAmount = "0";
  totalPayment = "0";
  totalInterest = "0";
  firstInterest = "0";
  firstPrincipal = "0";
  totalMonthsSaved = "0";
  yearsSaved = "0";
  monthsSaved = "0";
  interestSaved = "0";
  originalMonths = 15;
  months = 15;
  content = [];
  la = 0;
  monthlyRate = 0;
  monthlyPay = 0;
  monthlyExtra = 0;
  tempArray = [];
  progressBar = 0;

  calculatorForm = this.fb.group({
    propPrice: this.fb.control(''),
    downPayment: this.fb.control(''),
    interestRate: this.fb.control(''),
    loanTerm: this.fb.control(''),
    monthlyExtra: this.fb.control('')
  });
  monthsForm = this.fb.group({
    months: this.fb.control('')
  });

  private subscriptions: Subscription[] = [];
  small: boolean;
  medium: boolean;

  constructor(
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit() {
    let s: Subscription;
    s = this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large
    ]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small]) {
        this.activateHandsetLayout();
      } else if (result.breakpoints[Breakpoints.Medium] || result.breakpoints[Breakpoints.Large]) {
        this.activateMediumLayout();
      }
    });
  }

  activateHandsetLayout() {
    this.small = true;
    this.medium = false;
  }

  activateMediumLayout() {
    this.small = false;
    this.medium = true;
  }

  @Input() set reservePrice(price: number) {
    this.calculatorForm.controls['propPrice'].setValue(price);
    this.calculatorForm.controls['downPayment'].setValue(10);
    this.calculatorForm.controls['interestRate'].setValue(3.5);
    this.calculatorForm.controls['loanTerm'].setValue(33);
    this.calculatorForm.controls['monthlyExtra'].setValue(0);
    this.onChange();
  }

  onChange() {
    let calc = this.calculatorForm.value;

    //r = monthly interest rate
    let r = calc.interestRate / 1200;
    this.monthlyRate = r;

    //n = number of terms(months)
    let n = calc.loanTerm * 12;
    this.months = n;
    this.originalMonths = n;

    //dp = down payment
    let dp = (calc.downPayment / 100) * calc.propPrice;

    //la = loan amount
    let la = calc.propPrice - dp;
    this.la = la;
    let laString = Math.round(la).toLocaleString();
    let rate = Math.pow((1 + r), n);

    //mp = monthly payment
    let mp = (la) * ((r * rate) / (rate - 1));

    //mx = monthly extra payment
    let mx = calc.monthlyExtra;
    this.monthlyExtra = mx;
    mp += mx;

    this.monthlyPay = mp;
    let mpString = Math.round(mp).toLocaleString();

    //tp = total payment
    let tp = mp * n;
    let tpString = Math.round(tp).toLocaleString();

    //ti = total intertest
    let ti = tp - la;
    let tiString = Math.round(ti).toLocaleString();

    //fi = first interest
    let fi = r * la;
    let fiString = Math.round(fi).toLocaleString();

    //fp = first principal
    let fp = mp - fi;
    let fpString = Math.round(fp).toLocaleString();

    //ppb = pre-payment balance
    let ppb = fi + la;

    //lb = loan balance
    let lb = la - fp;
    this.progressBar = (fi / mp) * 100;

    if (!isNaN(mp) && isFinite(mp)) {
      for (let i = 0; i < this.months; i++) {
        this.tempArray[i] = [];
        this.tempArray[i][0] = i + 1;
      }

      this.monthlyPayment = mpString;
      this.loanAmount = laString;
      this.totalPayment = tpString;
      this.totalInterest = tiString;
      this.firstInterest = fiString;
      this.firstPrincipal = fpString;

      this.tempArray[0].push(Math.round(fi * 100) / 100);
      this.tempArray[0].push(Math.round(ppb * 100) / 100);
      this.tempArray[0].push(Math.round(mp * 100) / 100);
      this.tempArray[0].push(Math.round(lb * 100) / 100);
      this.tempArray[0].push(Math.round(fp * 100) / 100);
      this.generate();
    }
  }

  generate() {
    this.content = [];
    for (let i = 1; i < this.months; i++) {
      let mp = this.monthlyPay;
      let ai = this.monthlyRate * this.tempArray[i - 1][4];
      let p = this.monthlyPay - ai;
      let ppb = this.tempArray[i - 1][2] - p;
      let lb = ppb - this.monthlyPay;

      this.tempArray[i][1] = Math.round(ai * 100) / 100
      this.tempArray[i][2] = Math.round(ppb * 100) / 100;

      if (lb <= 0) {
        this.tempArray[i][3] = Math.round((ppb) * 100) / 100;
        this.months = i + 1;
        this.tempArray[i][4] = 0;
        let tp = (mp * (i)) + (ppb - ai);
        let tpString = Math.round(tp).toLocaleString();
        this.totalPayment = tpString;
        let ti = tp - this.la;
        let tiString = Math.round(ti).toLocaleString();
        this.totalInterest = tiString;
        this.tempArray[i][5] = Math.round((ppb - ai) * 100) / 100;

        let tms = this.originalMonths - this.months;
        let ms = tms % 12;
        let ys = Math.trunc(tms / 12);
        this.totalMonthsSaved = tms.toLocaleString();
        this.monthsSaved = ms.toLocaleString();
        this.yearsSaved = (ys).toLocaleString();

        let tpOriginal = (mp - this.monthlyExtra) * this.originalMonths;
        if (this.calculatorForm.value.monthlyExtra > 0) {
          const tempNum = Math.round((tpOriginal - tp) * 100) / 100;
          this.interestSaved = tempNum.toLocaleString();
        }
        else {
          this.interestSaved = '0';
        }
      }
      else {
        this.tempArray[i][3] = Math.round(mp * 100) / 100;
        this.tempArray[i][4] = Math.round(lb * 100) / 100;
        this.tempArray[i][5] = Math.round(p * 100) / 100;
        this.totalMonthsSaved = "0";
        this.monthsSaved = "0";
        this.yearsSaved = "0";
        this.interestSaved = "0";
      }


    }
    for (let i = 0; i < this.months; i++) {
      this.content[i] = [];
      for (let j = 0; j < 6; j++) {
        this.content[i][j] = this.tempArray[i][j];
      }
    }


    // for (let i = 0; i < 10; i++) {
    //   this.content[i] = [];
    //   for (let j = 0; j < 6; j++) {
    //     this.content[i][j] = this.tempArray[i][j];
    //   }
    // }
    // let k = 15;
    // for (let i = this.months - 10; i < this.months; i++) {
    //   this.content[k] = [];
    //   for (let j = 0; j < 6; j++) {
    //     this.content[k][j] = this.tempArray[i][j];
    //   }
    //   k++;
    // }
  }

}
