import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-featured-carousel',
  templateUrl: './featured-carousel.component.html',
  styleUrls: ['./featured-carousel.component.scss']
})
export class FeaturedCarouselComponent implements OnInit {
  @ViewChild('mainCarousel') mainCarousel: CarouselComponent;
  @Input() propertyDetails = [];
  notFeatured = false;
  mainOptions: OwlOptions = {
    responsive: {
      0: {
        items: 4
      },
    },
    autoHeight: true,
    center: false,
    stagePadding: 0,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true
  };

  constructor(
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    let s: Subscription;
    s = this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large
    ]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small]) {
        this.activateHandsetLayout();
      } else if (result.breakpoints[Breakpoints.Medium] || result.breakpoints[Breakpoints.Large]) {
        this.activateMediumLayout();
      }
    });
  }

  activateHandsetLayout() {    
    this.mainOptions.responsive[0].items = 2;
  }

  activateMediumLayout() {
    this.mainOptions.responsive[0].items = 4;
  }

  next() {
    this.mainCarousel.next();
  }

  previous() {
    this.mainCarousel.prev();
  }

}
