<h2 mat-dialog-title>Login</h2>
<mat-dialog-content>
  <form id="login" [formGroup]="loginForm">
    <div class="d-flex flex-column">
      <mat-form-field color="accent">
        <mat-label>E-mail</mat-label>
        <input formControlName="email" matInput type="email" email />
        <mat-error>Invalid E-mail</mat-error>
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Password</mat-label>
        <input formControlName="password" matInput type="password" />
        <mat-error>Invalid password</mat-error>
      </mat-form-field>
    </div>
  </form>
  <div class="d-flex justify-content-between">
    <div>
      <p class="mb-1">
        <a [href]="forgotPasswordLink">Forgot password</a>
      </p>
      <p class="mb-1">
        <a [href]="forgotPasswordLink">Resend verification</a>
      </p>
    </div>
    <div class="d-flex align-items-end">
      <button mat-raised-button color="accent" (click)="onLogin()" form="login">
          Login
      </button>
      <button mat-raised-button color="warn" class="mx-1" mat-dialog-close>Cancel</button>
    </div>
  </div>
</mat-dialog-content>