import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctionCalendarComponent } from './auction-calendar.component';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { CalendarModule, DateAdapter, CalendarNativeDateFormatter, DateFormatterParams, CalendarDateFormatter, CalendarEventTitleFormatter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';

class CustomDateFormatter extends CalendarNativeDateFormatter {

  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
  }

}

@NgModule({
  declarations: [AuctionCalendarComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
  ],
  providers: [
    CalendarEventTitleFormatter,
    { provide: CalendarDateFormatter, useClass: CustomDateFormatter }
  ],
  exports: [
    AuctionCalendarComponent
  ]
})
export class AuctionCalendarModule { }
