<div class="parent">
    <owl-carousel-o #mainCarousel class="main-carousel" [options]="mainOptions">
        <ng-container *ngFor="let detail of propertyDetails; let i = index">
            <ng-template carouselSlide>
                <div class="main-img-container">
                    <app-property-card
                        [notFeatured]="isFeatured"
                        [index]="i"
                        [status]="detail.status"
                        [propertyID]="detail.propertyID"
                        [propertyName]="detail.propertyName"
                        [reservePrice]="detail.reservePrice"
                        [auctionDate]="detail.auctionDate"
                        [landArea]="detail.landArea"
                        [tenure]="detail.tenure"
                        [streetNumber]="detail.streetNumber"
                        [route]="detail.route"
                        [city]="detail.city"
                        [postcode]="detail.postcode"
                        [state]="detail.state"
                        [propertyType]="detail.propertyType"
                        [favourited]="detail.favourited"
                    ></app-property-card>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
    <div *ngIf="propertyDetails.length > 1" class="left-button clickable d-flex align-items-center justify-content-center"
        (click)="previous()">
        <!-- <mat-icon class="arrow clickable">chevron_left</mat-icon> -->
        <img id="arrow-left" src="https://urbanparadigm.github.io/storage/arrow.png">
    </div>
    <div *ngIf="propertyDetails.length > 1" class="right-button clickable d-flex align-items-center justify-content-center"
        (click)="next()">
        <!-- <mat-icon class="arrow clickable">chevron_right</mat-icon> -->
        <img id="arrow-right" src="https://urbanparadigm.github.io/storage/arrow.png">
    </div>
</div>

