import { Component, OnInit } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { last, map } from 'rxjs/operators';
import { BaseService } from '../services/base.service';

@Component({
  selector: 'app-selling-form',
  templateUrl: './selling-form.component.html',
  styleUrls: ['./selling-form.component.scss']
})
export class SellingFormComponent implements OnInit {
  propertyTypes: Observable<any>;
  states: Observable<any>;
  tenures: Observable<any>;
  ownerRaces: Observable<any>;
  restrictions: Observable<any>;
  lotTypes: Observable<any>;
  image: any;
  thumbnail: any;
  imgURL: any;
  
  sellingEnquiryForm = this.fb.group({
    fullName: this.fb.control('', [Validators.required]),
    icNumber: this.fb.control('', [Validators.required]),
    email: this.fb.control('', [Validators.required]),
    contactNumber: this.fb.control('', [Validators.required]),
    propertyName: this.fb.control('', [Validators.required]),
    propertyType: this.fb.control('', [Validators.required]),
    reservePrice: this.fb.control('', [Validators.required]),
    landArea: this.fb.control('', [Validators.required]),
    address: this.fb.control('', [Validators.required]),
    state: this.fb.control('', [Validators.required]),
    tenure: this.fb.control('', [Validators.required]),
    bedroom: this.fb.control(''),
    bathroom: this.fb.control(''),
    carPark: this.fb.control(''),
    storey: this.fb.control(''),
    ownerRace: this.fb.control(''),
    restriction: this.fb.control(''),
    lotType: this.fb.control(''),
  });

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder,
    private remoteConfig: AngularFireRemoteConfig,
  ) {
    this.propertyTypes = this.remoteConfig.strings.propertyTypes.pipe(
      last(),
      map(o => {
        return JSON.parse(o);
      }),
    );
    this.states = this.remoteConfig.strings.states.pipe(
      last(),
      map(o => {
        return JSON.parse(o);
      }),
    );
    this.tenures = this.remoteConfig.strings.tenures.pipe(
      last(),
      map(o => {
        return JSON.parse(o);
      }),
    );
    this.ownerRaces = this.remoteConfig.strings.ownerRaces.pipe(
      last(),
      map(o => {
        return JSON.parse(o);
      }),
    );
    this.restrictions = this.remoteConfig.strings.restrictions.pipe(
      last(),
      map(o => {
        return JSON.parse(o);
      }),
    );
    this.lotTypes = this.remoteConfig.strings.lotTypes.pipe(
      last(),
      map(o => {
        return JSON.parse(o);
      }),
    );
  }

  ngOnInit(): void {
  }

  setImage(event: any) {
    this.image = event.target.files[0];
    this.baseService.resizeImage(this.image, 300, 300).then(blob => {
      this.thumbnail = blob;
    })
    var reader = new FileReader();
    reader.readAsDataURL(this.image);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }

  submitEnquiry() {
    this.sellingEnquiryForm.markAllAsTouched();
    if (this.sellingEnquiryForm.valid && this.image) {
      var params = this.sellingEnquiryForm.value;
      this.baseService.submitSellingForm(params, this.image);
    }
  }

}
