import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseService } from '../services/base.service';

@Component({
  selector: 'app-event-news',
  templateUrl: './event-news.component.html',
  styleUrls: ['./event-news.component.scss']
})
export class EventNewsComponent implements OnInit {
  collectionSize = 0;
  pageSize = 0;
  maxSize = 3;
  page = 1;
  news = [];
  paginatedNews = []

  isAdmin = false;

  constructor(
    private baseService: BaseService,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    let s: Subscription;
    s = this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large
    ]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small]) {
        this.activateHandsetLayout();
      } else if (result.breakpoints[Breakpoints.Medium] || result.breakpoints[Breakpoints.Large]) {
        this.activateMediumLayout();
      }
    });

    if (this.baseService.adminList.includes(this.baseService.username)) {
      this.isAdmin = true;
    }

    this.getAllEventNews();
  }

  activateHandsetLayout() {
    this.pageSize = 1;
    var activeImages = this.news;
    if (!this.isAdmin) {
      activeImages = this.news.filter(details => details.activated == true);
    }
    this.paginatedNews = this.paginateElements(this.pageSize, activeImages);
  }

  activateMediumLayout() {
    this.pageSize = 3;
    var activeImages = this.news;
    if (!this.isAdmin) {
      activeImages = this.news.filter(details => details.activated == true);
    }
    this.paginatedNews = this.paginateElements(this.pageSize, activeImages);
  }

  paginateElements(pageSize: number, elements: any[]): any {
    var paginated = [];
    var tempArray = [];
    var totalPages = Math.ceil(elements.length / pageSize);
    for (let i = 0; i < totalPages; i++) {
      for (let j = 0; j < pageSize; j++) {
        const index = i * pageSize + j;
        if (elements[index]) {
          tempArray.push(elements[index]);
        }
      }
      paginated.push(tempArray);
      tempArray = [];
    }
    return paginated;
  }

  getAllEventNews() {
    this.baseService.getAllEventNews().toPromise().then(o => {
      o.docs.map(event => {
        this.news.push(event.data())
      })
      var activeImages = this.news.sort((n1, n2) => {
        if (n1.date > n2.date) {
          return -1;
        }
        if (n1.date < n2.date) {
          return 1;
        }
        return 0;
      });
      if (!this.isAdmin) {
        activeImages = this.news.filter(details => details.activated == true);
      }
      this.paginatedNews = this.paginateElements(this.pageSize, activeImages);
    })
  }

  changeStatus(eventID: string, activated: boolean) {
    var newStatus = !activated
    this.baseService.adminChangeNewsStatus(eventID, newStatus).then(() => {
      window.alert("News status changed");
      window.location.reload();
    }).catch(e => {
      window.alert(e);
      window.location.reload();
    });
  }

}
