import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { endOfDay, endOfMonth, startOfDay, startOfMonth, } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, } from 'angular-calendar';
import { BaseService } from '../services/base.service';

@Component({
  selector: 'app-auction-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './auction-calendar.component.html',
  styleUrls: ['./auction-calendar.component.scss']
})
export class AuctionCalendarComponent implements OnInit {
  @Output() searched = new EventEmitter<any>();
  @Output() todayClicked = new EventEmitter<any>();
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh = new Subject<void>();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  constructor(
    private modal: NgbModal,
    private baseService: BaseService,
  ) { }

  ngOnInit(): void {
    var start = new Date();
    start.setDate(1);
    start.setMonth(start.getMonth() - 1);

    var end = new Date();
    end.setDate(1);
    end.setMonth(end.getMonth() + 1);

    const startDate = startOfMonth(start).getTime();
    const endDate = endOfMonth(end).getTime();

    this.getPropertyByDate(startDate, endDate);
  }

  dayClicked(event) {
    if (event.events.length != 0) {
      this.searched.emit(event);
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  getPropertyByDate(startDate: number, endDate: number) {
    var todayEvent = {
      badgeTotal: 0,
      date: new Date(),
      events: [],
      inMonth: true,
      isFuture: false,
      isPast: false,
      isToday: true,
      isWeekend: false,
    }
    this.baseService.getPropertiesByDate(startDate, endDate).toPromise().then(o => {
      o.docs.map(doc => {
        var params = doc.data();
        const event = {
          start: params.auctionTime,
          end: endOfDay(new Date(params.auctionDate)),
          title: params.bank
        }
        this.events.push(event)

        if (startOfDay(new Date()) <= new Date(params.auctionTime) && endOfDay(new Date()) >= new Date(params.auctionTime)) {
          todayEvent.events.push(event)
        }
      })
      this.todayClicked.emit(todayEvent);
      this.refresh.next();
    })
  }

}
