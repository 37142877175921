<mat-card class="primary-intermediate pb-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-6">
                <p class="mat-title mb-0">Total</p>
            </div>
            <div class="col-6" style="text-align: end;">
                <p class="mat-title mb-0">{{ total }}</p>
            </div>
        </div>
        <div *ngIf="showAllStates">
            <div *ngFor="let propertyCount of propertyCounts" (click)="search(propertyCount[0])" class="row clickable">
                <div class="col-8">
                    <p class="mat-subheading-2 mb-0">{{ propertyCount[0] }}</p>
                </div>
                <div class="col-4" style="text-align: end;">
                    <p class="mat-subheading-2 mb-0">{{ propertyCount[1] }}</p>
                </div>
            </div>
        </div>
        <div class="center-text" (click)="showAll()" *ngIf="!showAllStates; else arrowUpBlock">
            <mat-icon class="clickable">keyboard_arrow_down</mat-icon>
        </div>
        <ng-template #arrowUpBlock>
            <div class="center-text" (click)="showAll()">
                <mat-icon class="clickable">keyboard_arrow_up</mat-icon>
            </div>
        </ng-template>
    </div>
</mat-card>