import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventVideoComponent } from './event-video.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [EventVideoComponent],
  imports: [
    CommonModule,
    NgbModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    EventVideoComponent
  ]
})
export class EventVideoModule { }
