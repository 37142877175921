import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarEventComponent } from './calendar-event.component';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [CalendarEventComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    CalendarEventComponent
  ]
})
export class CalendarEventModule { }
