import { Component, OnInit } from '@angular/core';
import { BaseService } from '../services/base.service';

@Component({
  selector: 'app-first-visit-popup',
  templateUrl: './first-visit-popup.component.html',
  styleUrls: ['./first-visit-popup.component.scss']
})
export class FirstVisitPopupComponent implements OnInit {

  href = "";
  imgsrc = "";

  constructor(
    private baseService: BaseService
  ) { }

  ngOnInit(): void {
    this.baseService.getPopup(true, false).subscribe(o => {
      this.href = o[0].href;
      this.imgsrc = "https://firebasestorage.googleapis.com/v0/b/auction-directory.appspot.com/o/popUp%2F" + o[0].uuid + "%2Fimage0?alt=media"
    })
  }

}
