<mat-card>
    <div class="row">
        <div class="col-12 col-md-6 mb-1">
            <p class="mat-h2">Subscriber details</p>
            <p>Username: <span class="bold-text">{{details.username}}</span></p>
            <p>Email: <span class="bold-text">{{details.email}}</span></p>
            <p>Phone number: <span class="bold-text">{{details.phoneNumber}}</span></p>
        </div>
        <div class="col-12 col-md-6 mb-1">
            <p class="mat-h2">Admin actions</p>
            <div *ngFor="let proof of details.proofOfPayments; let i = index">
                <a [href]="proof" target="_blank">Payment proof {{i+1}}</a>
            </div>
            <form id="reason" [formGroup]="reasonForm">
                <div class="d-flex flex-column">
                    <mat-form-field color="accent">
                        <mat-label>Rejection reason</mat-label>
                        <input formControlName="reason" matInput />
                    </mat-form-field>
                </div>
            </form>
            <div class="col-12 d-flex justify-content-end">
                <button mat-raised-button class="mr-1" color="warn"
                    (click)="adminEditSubscription('REJECTED')">Reject</button>
                <button mat-raised-button color="accent"
                    (click)="adminEditSubscription('APPROVED')">Approve</button>
            </div>
        </div>
    </div>
</mat-card>