import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireFunctions } from '@angular/fire/functions';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as uuid from 'uuid';

import { MatSnackBar } from '@angular/material/snack-bar';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
import { startOfMonth } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  adminList = ["AdminCarmen", "AdminUpload"]
  uid = null;
  username = null;
  role = null;
  phoneNumber = null;
  facebookName = null;
  email = null;
  favourite = [];
  featured = [];
  casaAccessToken = null;
  casaRoles = null;
  casaAgentRank = null;
  user$: Observable<any>;
  userRef: AngularFirestoreCollection;
  propertiesRef: AngularFirestoreCollection;
  memberLockedRef: AngularFirestoreCollection;
  server = environment.server;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private afStorage: AngularFireStorage,
    private router: Router,
    private http: HttpClient,
    private afFunc: AngularFireFunctions,
    private snackBar: MatSnackBar,
    private ngZone: NgZone
  ) {
    this.userRef = afs.collection('users');
    this.propertiesRef = afs.collection('properties');
    this.memberLockedRef = afs.collection('memberLocked');
    this.uid = localStorage.getItem('uid');
    this.phoneNumber = localStorage.getItem('phoneNumber');
    this.email = localStorage.getItem('email');
    this.username = localStorage.getItem('username');
    this.role = localStorage.getItem('role');
  }

  functionTest(data): Observable<any> {
    const test = this.afFunc.httpsCallable('test');
    return test(data);
  }

  requestTest(data): Observable<any> {
    const test = this.afFunc.httpsCallable('requestTest');
    return test(data);
  }

  functionLogger(message): Observable<any> {
    const logger = this.afFunc.httpsCallable('logger');
    var data = {
      uid: this.uid,
      message: message
    }
    return logger(data);
  }

  addReferrer(params: any): Observable<any> {
    const addReferrer = this.afFunc.httpsCallable('addReferrer');
    return addReferrer(params);
  }

  addAgent(params: any) {
    const addAgent = this.afFunc.httpsCallable('addAgent');
    addAgent(params).subscribe(o => {
      if (o.status == 'OK') {
        if (params.referral) {
          var referrerParams = {
            username: params.referral,
            referrer: {
              username: params.username,
              time: new Date().getTime(),
            }
          }
          this.addReferrer(referrerParams).subscribe();
        }
        this.functionLogger(o.result.uid + " SIGNED UP").subscribe();
        const userParam = {
          username: params.username,
          image: params.image,
        }
        this.uploadProfilePicture(userParam);
        window.alert(o.message);
        this.ngZone.run(() => this.router.navigate(['/']));
      } else {
        window.alert(o.message);
      }
    })
  }

  functionCopyImage(params: any): Observable<any> {
    const copyImage = this.afFunc.httpsCallable('copyImage');
    return copyImage(params);
  }

  uploadProfilePicture(userParam: any) {
    const filePath = "users/" + userParam.username + "/image0";
    const ref = this.afStorage.ref(filePath);
    ref.put(userParam.image);
  }

  async googleSignIn() {
    const provider = new auth.GoogleAuthProvider();
    await this.afAuth.signInWithPopup(provider).then(o => {
      console.log(o);
    }).catch(function (error) {
      console.log(error);
    });
  }

  functionCreateUser(params: any): Observable<any> {
    const createMember = this.afFunc.httpsCallable('createMember');
    return createMember(params);
  }

  functionUpdateUser(params: any): Observable<any> {
    const updateMember = this.afFunc.httpsCallable('updateMember');
    return updateMember(params);
  }

  forgotPassword(email: string) {
    this.afAuth.sendPasswordResetEmail(email).then(() => {
      window.alert("An email to reset password has been sent to " + email + ". It may also be sent to spam of your inbox")
    })
  }

  resendVerification(params: any): Observable<any> {
    const resendVerification = this.afFunc.httpsCallable('resendVerification');
    return resendVerification(params);
  }

  signIn(email: string, password: string) {
    this.afAuth.signInWithEmailAndPassword(email, password).then((o: any) => {
      console.log(o);
      if (o.user.emailVerified) {
        const username = o.user.displayName;
        this.getUserList(username, true).subscribe(o => {
          if (o) {
            window.location.reload();
          }
        });
      } else {
        window.alert("Email unverified, please verify your email")
        this.signOut()
      }
    }).catch((e: any) => {
      window.alert(e.message);
      window.location.reload();
    })
  }

  async signOut() {
    this.uid = null
    this.username = null
    this.role = null
    this.phoneNumber = null
    this.facebookName = null
    this.email = null
    localStorage.removeItem('uid');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('facebookName');
    localStorage.removeItem('fullName');
    sessionStorage.clear();
    this.router.navigate(['/']);
    await this.afAuth.signOut().then((o: any) => {
      window.location.reload();
    });
  }

  getAllUsers(): Observable<any> {
    return this.afs.collection('users', ref => ref.orderBy('updated', 'desc')).valueChanges();
  }

  getMemberList(role: string): Observable<any> {
    return this.afs.collection('users', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (role) {
        query = query.where('role', 'array-contains', role).orderBy('username', 'desc');
      }
      return query;
    }).valueChanges();
  }

  adminUpdateUser(params: any) {
    this.afs.collection('users').doc(params.username).set(params);
  }

  async updateAgentPackage(packageParams, tngQR) {
    if (tngQR) {
      const filePath = "packages/agentPackage/tngQR";
      const ref = this.afStorage.ref(filePath);
      await ref.put(tngQR);
    }
    this.afs.collection("packages").doc("agentPackage").set(packageParams).then(() => {
      window.alert("Package successfully updated");
      window.location.reload();
    }).catch(error => {
      window.alert(error)
    });
  }

  getAgentPackageDetails() {
    return this.afs.collection("packages").doc("agentPackage").get();
  }

  async agentSubscribePackage(proofOfPayments) {
    const subscriptionID = uuid.v4();
    var subscriptionParams = {
      subscriptionID: subscriptionID,
      uid: this.uid,
      username: this.username,
      phoneNumber: this.phoneNumber,
      email: this.email,
      status: "CREATED",
      reason: null,
      proofOfPayments: [],
      updated: new Date().getTime()
    }
    for (let i = 0; i < proofOfPayments.length; i++) {
      const filePath = "packageSubscription/" + subscriptionID + "/image" + i;
      const ref = this.afStorage.ref(filePath);
      subscriptionParams.proofOfPayments[i] = "https://firebasestorage.googleapis.com/v0/b/auction-directory.appspot.com/o/packageSubscription%2F" + subscriptionID + "%2Fimage" + i + "?alt=media"
      await ref.put(proofOfPayments[i]);
    }
    this.afs.collection("subscriptions").doc(subscriptionID).set(subscriptionParams).then(() => {
      window.alert("Subscription submitted, pending admin approval");
      window.location.reload();
    }).catch(error => {
      window.alert(error);
    })
  }

  getUserSubscriptions() {
    return this.afs.collection('subscriptions', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('uid', '==', this.uid);
      return query.orderBy('updated', 'desc');
    }).get();
  }

  adminGetSubscriptions(status: string) {
    return this.afs.collection('subscriptions', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('status', '==', status);
      return query;
    }).get();
  }

  adminEditAgentSubscription(username: string, subscriptionID: string, statusChange: string, reason?: string) {
    if (statusChange == "APPROVED") {
      var approveParams = {
        status: statusChange,
        updated: new Date().getTime()
      }
      this.afs.collection("subscriptions").doc(subscriptionID).update(approveParams).then(() => {
        const subscriptionParams = {
          agentSubscribed: true,
          agentSubscriptionDate: new Date().getTime(),
          firstTimeSubscribed: true
        }
        this.afs.collection('users').doc(username).update(subscriptionParams).then(() => {
          this.afs.collection('users').doc(username).update({
            currentPackage: firebase.firestore.FieldValue.arrayUnion("AGENT_ANNUAL_SUBSCRIPTION")
          }).then(() => {
            window.alert("Subscription approved");
            window.location.reload();
          }).catch(error => {
            window.alert(error);
          })
        }).catch(error => {
          window.alert(error);
        });
      }).catch(error => {
        window.alert(error);
      })
    } else if (statusChange == "REJECTED") {
      var rejectParams = {
        status: statusChange,
        reason: reason,
        updated: new Date().getTime()
      }
      this.afs.collection("subscriptions").doc(subscriptionID).update(rejectParams).then(() => {
        window.alert("Subscription rejected");
        window.location.reload();
      }).catch(error => {
        window.alert(error);
      })
    }
  }

  getUserList(username: string, signIn: boolean): Observable<any> {
    return this.userRef.doc(username).valueChanges().pipe(
      tap((o: any) => {
        if (o) {
          if (signIn) {
            localStorage.setItem('uid', o.uid);
            localStorage.setItem('username', o.username);
            localStorage.setItem('role', o.role);
            localStorage.setItem('phoneNumber', o.phoneNumber);
            localStorage.setItem('facebookName', o.facebookName);
            localStorage.setItem('fullName', o.fullName);
            localStorage.setItem('email', o.email);
            this.uid = o.uid;
            this.username = o.username;
            this.role = o.role;
            this.phoneNumber = o.phoneNumber;
            this.facebookName = o.facebookName;
            this.email = o.email;
          }
        }
      })
    );
  }

  getFavourite(username): Observable<any> {
    return this.userRef.doc(username).valueChanges().pipe(
      tap((o: any) => {
        if (o.favourite) {
          this.favourite = o.favourite;
        }
      })
    )
  }

  getFeatured(username): Observable<any> {
    return this.userRef.doc(username).valueChanges().pipe(
      tap((o: any) => {
        if (o.featured) {
          this.featured = o.featured;
        }
      })
    );
  }

  getMyProperties(uploadedBy: string): Observable<any> {
    return this.afs.collection('properties', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('uploadedBy', '==', uploadedBy);
      query = query.where('auctionDate', '>=', this.removeTime(Date.now())).orderBy('auctionDate', 'asc').limit(120);
      return query;
    }).valueChanges();
  }

  getGeneralFeatured(): Observable<any> {
    return this.afs.collection("generalFeatured").doc("general").valueChanges();
  }

  toggleFavourite(params: any) {
    this.userRef.doc(params.username).update({ favourite: params.favourite });
    this.favourite = params.favourite;
  }

  toggleFeatured(params: any) {
    this.userRef.doc(params.username).update({ featured: params.featured });
    this.featured = params.featured;
  }

  toggleGeneralFeatured(params: any) {
    this.afs.collection("generalFeatured").doc("general").update(params);
  }

  getUserStorage(username: string): Observable<any> {
    const filePath = "users/" + username + "/image0";
    let ref = this.afStorage.ref(filePath);
    return ref.getDownloadURL();
  }

  async changeFullName(params: any) {
    this.userRef.doc(params.username).update({ fullName: params.newFullName }).then(() => {
      window.alert('Full name changed');
    });
  }

  async changePhoneNumber(params: any) {
    this.userRef.doc(params.username).update({ phoneNumber: params.newPhoneNumber }).then(() => {
      window.alert('Phone number changed');
    });
  }

  async changeEmail(params: any) {
    var user = this.afAuth.currentUser;
    (await user).updateEmail(params.newEmail).then(o => {
      this.userRef.doc(params.username).update({
        email: params.newEmail,
      })
      window.alert('E-mail changed');
    }).catch(e => {
      window.alert(e);
      console.log(e);
    });
  }

  async changePassword(params: any) {
    var user = this.afAuth.currentUser;
    (await user).updatePassword(params.newPassword).then(o => {
      window.alert('Password changed');
    }).catch(e => {
      console.log(e);
    });
  }

  async changeFacebookName(params: any) {
    this.userRef.doc(params.username).update({ facebookName: params.newFacebookName }).then(() => {
      window.alert('Facebook Name changed');
    });
  }

  async changePic(params: any) {
    const thumbnailPath = "users/" + params.username + "/thumbnail";
    const thumbnailRef = this.afStorage.ref(thumbnailPath);
    await thumbnailRef.put(params.thumbnail);
    const filePath = "users/" + params.username + "/image0";
    const ref = this.afStorage.ref(filePath);
    await ref.put(params.image);
    window.alert('Profile picture changed');
  }

  updateContactCard(params: any) {
    this.userRef.doc(params.username).update({ cardPattern: params.cardPattern }).then(() => {
      window.alert('Contact card design updated');
    });
  }

  disableAgent(params) {
    const subscription = this.afs.collection('admin').doc(this.uid).valueChanges().subscribe(o => {
      if (o) {
        subscription.unsubscribe();
        const disableAgent = this.afFunc.httpsCallable('disableAgent');
        disableAgent(params).subscribe(o => {
          if (o.status == "OK") {
            // this.userRef.doc(params.username).update({
            //   disabled: true,
            //   updated: new Date().getTime(),
            // })
            this.functionLogger('DEACTIVATED AGENT: ' + o.result.uid).subscribe();
            window.alert(o.message);
            location.reload();
          }
        });
      }
    });
  }

  enableAgent(params) {
    const subscription = this.afs.collection('admin').doc(this.uid).valueChanges().subscribe(o => {
      if (o) {
        subscription.unsubscribe();
        const enableAgent = this.afFunc.httpsCallable('enableAgent');
        enableAgent(params).subscribe(o => {
          if (o.status == "OK") {
            // this.userRef.doc(params.username).update({
            //   disabled: false,
            //   updated: new Date().getTime(),
            // })
            this.functionLogger('ACTIVATED AGENT: ' + o.result.uid).subscribe();
            window.alert(o.message);
            location.reload();
          }
        });
      }
    });
  }

  async uploadProperty(params: any) {
    var posUUID = null
    var error = false;
    if (params.image.length > 0) {
      const filePath = "properties/" + params.propertyID + "/thumbnail";
      const ref = this.afStorage.ref(filePath);
      await ref.put(params.thumbnail);
    }
    for (let i = 0; i < params.image.length; i++) {
      const filePath = "properties/" + params.propertyID + "/image" + i;
      const ref = this.afStorage.ref(filePath);
      await ref.put(params.image[i]);
    }
    if (params.pos) {
      posUUID = uuid.v4();
      await this.uploadPOS(posUUID, params.pos);
    }
    this.memberLockedRef.doc(params.propertyID).set({
      auctionType: params.auctionType,
      auctionVenue: params.auctionVenue,
      auctioneer: params.auctioneer,
      borrower: params.borrower,
      solicitor: params.solicitor,
      bank: params.bank,
      serviceCharges: params.serviceCharges,
      remark: params.remark,
      additionalInfo: params.additionalInfo,
      auctionDate: params.auctionDate,
      auctionTime: params.auctionTime,
      posUUID: posUUID,

      propertyName: params.propertyName,
      keywords: params.keywords,
      propertyType: params.propertyType,
      propertyID: params.propertyID,
      reservePrice: params.reservePrice,
      landArea: params.landArea,
      fullAddress: params.fullAddress,
      streetNumber: params.streetNumber,
      route: params.route,
      city: params.city,
      postcode: params.postcode,
      state: params.state,
      country: "Malaysia",
      lat: "",
      lng: "",
      tenure: params.tenure,
      status: params.status,
      ownerRace: params.ownerRace,
      deposit: params.deposit,
      imgURL: "",
      reference: "",
      restriction: params.additionalInfo,
      edited: true,
      updated: [{ username: this.username, time: new Date().getTime() }],
      lastUpdated: this.username,
      lastUpdatedTime: new Date().getTime(),
      uploadedBy: this.username
    })
    await this.propertiesRef.doc(params.propertyID).set({
      propertyName: params.propertyName,
      keywords: params.keywords,
      propertyType: params.propertyType,
      propertyID: params.propertyID,
      reservePrice: params.reservePrice,
      landArea: params.landArea,
      fullAddress: params.fullAddress,
      streetNumber: params.streetNumber,
      route: params.route,
      city: params.city,
      postcode: params.postcode,
      state: params.state,
      country: "Malaysia",
      lat: "",
      lng: "",
      tenure: params.tenure,
      status: params.status,
      ownerRace: params.ownerRace,
      deposit: params.deposit,
      remark: params.remark,
      additionalInfo: params.additionalInfo,
      imgURL: "",
      auctionDate: params.auctionDate,
      reference: "",
      restriction: params.additionalInfo,
      edited: true,
      updated: [{ username: this.username, time: new Date().getTime() }],
      lastUpdated: this.username,
      lastUpdatedTime: new Date().getTime(),
      uploadedBy: this.username
    }).catch(e => {
      if (e) {
        error = true
      }
    });
    if (error) {
      window.alert("An error has occured");
    } else {
      this.updateTotal(params.state, true);
      this.functionLogger('UPLOADED PROPERTY: ' + params.propertyID).subscribe();
      window.alert("Upload successful");
      this.router.navigate(['/']);
    }
  }

  async updateProperty(params: any) {
    var posUUID = params.posUUID;
    var error = false;
    if (params.image[0] != "") {
      const thumbnailPath = "properties/" + params.propertyID + "/thumbnail";
      const thumbnailRef = this.afStorage.ref(thumbnailPath);
      await thumbnailRef.put(params.thumbnail);
      const filePath = "properties/" + params.propertyID + "/image0";
      const ref = this.afStorage.ref(filePath);
      await ref.put(params.image[0]);
    }
    for (let i = 1; i < params.image.length; i++) {
      const filePath = "properties/" + params.propertyID + "/image" + i;
      const ref = this.afStorage.ref(filePath);
      await ref.put(params.image[i]);
    }
    if (params.pos) {
      if (!params.posUUID) {
        posUUID = uuid.v4();
      }
      await this.uploadPOS(posUUID, params.pos);
    }
    this.memberLockedRef.doc(params.propertyID).update({
      auctionType: params.auctionType,
      auctionVenue: params.auctionVenue,
      auctioneer: params.auctioneer,
      borrower: params.borrower,
      solicitor: params.solicitor,
      bank: params.bank,
      serviceCharges: params.serviceCharges,
      remark: params.remark,
      additionalInfo: params.additionalInfo,
      auctionDate: params.auctionDate,
      auctionTime: params.auctionTime,
      posUUID: posUUID,

      propertyName: params.propertyName,
      keywords: params.keywords,
      propertyType: params.propertyType,
      propertyID: params.propertyID,
      reservePrice: params.reservePrice,
      landArea: params.landArea,
      fullAddress: params.fullAddress,
      streetNumber: params.streetNumber,
      route: params.route,
      city: params.city,
      postcode: params.postcode,
      state: params.state,
      tenure: params.tenure,
      status: params.status,
      ownerRace: params.ownerRace,
      deposit: params.deposit,
      restriction: params.additionalInfo,
      imgURL: "",
      edited: true,
      lastUpdated: this.username,
      lastUpdatedTime: new Date().getTime(),
      uploadedBy: this.username
    })
    await this.propertiesRef.doc(params.propertyID).update({
      propertyName: params.propertyName,
      keywords: params.keywords,
      propertyType: params.propertyType,
      propertyID: params.propertyID,
      reservePrice: params.reservePrice,
      landArea: params.landArea,
      fullAddress: params.fullAddress,
      streetNumber: params.streetNumber,
      route: params.route,
      city: params.city,
      postcode: params.postcode,
      state: params.state,
      tenure: params.tenure,
      status: params.status,
      ownerRace: params.ownerRace,
      deposit: params.deposit,
      remark: params.remark,
      additionalInfo: params.additionalInfo,
      restriction: params.additionalInfo,
      imgURL: "",
      auctionDate: params.auctionDate,
      edited: true,
      lastUpdated: this.username,
      lastUpdatedTime: new Date().getTime(),
      uploadedBy: this.username
    }).then(() => {
      const time = new Date().getTime();
      this.propertiesRef.doc(params.propertyID).update({
        updated: firebase.firestore.FieldValue.arrayUnion({ username: this.username, time: time })
      }).then(() => {
        this.memberLockedRef.doc(params.propertyID).update({
          updated: firebase.firestore.FieldValue.arrayUnion({ username: this.username, time: time })
        })
      })
    }).catch(e => {
      if (e) {
        console.log(e);
        error = true
      }
    });
    if (error) {
      window.alert("An error has occured");
    } else {
      this.functionLogger('UPDATED PROPERTY: ' + params.propertyID).subscribe();
      window.alert("Update successful");
      this.router.navigate(['/']);
    }
  }

  getCurrentUser() {
    return this.afs.collection('users').doc(this.username).get()
  }

  async uploadPOS(posUUID: string, pos: any) {
    const posPath = "pos/" + posUUID;
    let posRef = this.afStorage.ref(posPath);
    await posRef.put(pos);
  }

  getPropertyStorage(propertyID: string): Observable<any> {
    const filePath = "properties/" + propertyID + "/image0";
    let ref = this.afStorage.ref(filePath);
    return ref.getDownloadURL();
  }

  listAllImages(propertyID: string): Observable<any> {
    const filePath = "properties/" + propertyID;
    let ref = this.afStorage.ref(filePath);
    return ref.listAll();
  }

  getStorageImageUrl(path: string): Observable<any> {
    let ref = this.afStorage.ref(path);
    return ref.getDownloadURL();
  }

  getImageCount(params: any): Observable<any> {
    return this.http.get(this.server + '/casa/cx4/api/anon/getHomeBidImageCount', {
      params
    })
  }

  getProperties(): Observable<any> {
    return this.propertiesRef.valueChanges();
  }

  getPropertyCount(): Observable<any> {
    return this.afs.collection('propertyCount').doc('count').valueChanges();
  }

  updatePropertyCount(params: any) {
    this.afs.collection('propertyCount').doc('count').update(params);
  }

  getAllProperties(status: string, edited: boolean) {
    return this.afs.collection('memberLocked', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (edited) { query = query.where('edited', '==', edited); }
      query = query.where('status', '==', status);
      return query;
    }).get();
  }

  getExpiredProperties() {
    var start = new Date();
    start.setDate(1);
    start.setMonth(start.getMonth() - 6);

    const startDate = startOfMonth(start).getTime();

    return this.afs.collection('memberLocked', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('status', '==', 'Expired');
      query = query.where('auctionTime', '>=', startDate);
      return query;
    }).get();
  }

  getLatestProperties(): Observable<any> {
    return this.afs.collection('memberLocked', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('edited', '==', true);
      query = query.where('auctionDate', '>=', this.removeTime(Date.now())).orderBy('auctionDate', 'asc').limit(8);
      return query;
    }).valueChanges();
    // return this.afs.collection('properties', ref => ref.where('auctionDate', '>=', this.removeTime(Date.now())).orderBy('auctionDate', 'asc').limit(120)).valueChanges();
  }

  getPropertiesByDate(startDate: number, endDate: number): Observable<any> {
    return this.afs.collection('memberLocked', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('edited', '==', true);
      query = query.where('auctionDate', '>=', startDate);
      query = query.where('auctionDate', '<=', endDate);
      return query;
    }).get();
  }

  getCalendarProperties(bank: string, startDate: number, endDate: number) {
    return this.afs.collection('memberLocked', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('edited', '==', true);
      query = query.where('bank', '==', bank);
      query = query.where('auctionDate', '>=', startDate);
      query = query.where('auctionDate', '<=', endDate);
      return query;
    }).get();
  }

  getSuggestedProperty(fullAddress: string): Observable<any> {
    return this.afs.collection('properties', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('suggestedAddress', '==', fullAddress);
      return query;
    }).valueChanges();
  }

  //Events start here
  async adminSavePhoto(params: any, images: any) {
    let eventParams = params;
    for (let i = 0; i < images.length; i++) {
      const filePath = "eventPhoto/" + params.eventID + "/image" + i;
      const ref = this.afStorage.ref(filePath);
      await ref.put(images[i]);
      eventParams.images.push("https://firebasestorage.googleapis.com/v0/b/auction-directory.appspot.com/o/eventPhoto%2F" + params.eventID + "%2Fimage" + i + "?alt=media")
      if (i == 0) {
        eventParams.thumbnail = "https://firebasestorage.googleapis.com/v0/b/auction-directory.appspot.com/o/eventPhoto%2F" + params.eventID + "%2Fimage" + i + "?alt=media";
      }
    }
    return this.afs.collection('eventPhoto').doc(params.eventID).set(eventParams);
  }

  adminChangePhotoStatus(eventID: string, activated: boolean) {
    return this.afs.collection('eventPhoto').doc(eventID).update({ activated: activated });
  }

  getAllEventPhoto() {
    let q: any;
    if (this.adminList.includes(this.username)) {
      q = this.afs.collection('eventPhoto');
    } else {
      q = this.afs.collection('eventPhoto', ref => ref.where('activated', '==', true));
    }
    return q.get();
  }

  getEventPhotobyID(eventID: string) {
    return this.afs.collection('eventPhoto').doc(eventID).get();
  }

  async deleteEventPhotobyID(eventID: string, fileNumber: number) {
    for (let i = 0; i < fileNumber; i++) {
      const filePath = "eventPhoto/" + eventID + "/image" + i;
      const ref = this.afStorage.ref(filePath);
      ref.delete();
    }
    await this.afs.collection('eventPhoto').doc(eventID).delete().then(() => {
      window.alert("Event successfully deleted")
      window.location.reload();
    });
  }

  //event video starts
  async adminSaveVideo(params: any) {
    return this.afs.collection('eventVideo').doc(params.eventID).set(params);
  }

  adminChangeVideoStatus(eventID: string, activated: boolean) {
    return this.afs.collection('eventVideo').doc(eventID).update({ activated: activated });
  }

  getAllEventVideo() {
    let q: any;
    if (this.adminList.includes(this.username)) {
      q = this.afs.collection('eventVideo');
    } else {
      q = this.afs.collection('eventVideo', ref => ref.where('activated', '==', true));
    }
    return q.get();
  }

  async deleteEventVideobyID(eventID: string) {
    await this.afs.collection('eventVideo').doc(eventID).delete().then(() => {
      window.alert("Event successfully deleted")
      window.location.reload();
    });
  }

  //event news starts
  async adminSaveNews(params: any, thumbnail: any) {
    let eventParams = params;
    const filePath = "eventNews/" + params.eventID + "/thumbnail";
    const ref = this.afStorage.ref(filePath);
    await ref.put(thumbnail);
    eventParams.thumbnail = "https://firebasestorage.googleapis.com/v0/b/auction-directory.appspot.com/o/eventNews%2F" + params.eventID + "%2Fthumbnail?alt=media";
    return this.afs.collection('eventNews').doc(params.eventID).set(eventParams);
  }

  adminChangeNewsStatus(eventID: string, activated: boolean) {
    return this.afs.collection('eventNews').doc(eventID).update({ activated: activated });
  }

  getAllEventNews() {
    let q: any;
    if (this.adminList.includes(this.username)) {
      q = this.afs.collection('eventNews');
    } else {
      q = this.afs.collection('eventNews', ref => ref.where('activated', '==', true));
    }
    return q.get();
  }

  async deleteEventNewsbyID(eventID: string) {
    const filePath = "eventNews/" + eventID + "/thumbnail";
    const ref = this.afStorage.ref(filePath);
    ref.delete();
    await this.afs.collection('eventNews').doc(eventID).delete().then(() => {
      window.alert("Event successfully deleted")
      window.location.reload();
    });
  }
  //Events end here

  //property details
  getPropertyDetails(propertyID: string): Observable<any> {
    return this.propertiesRef.doc(propertyID).valueChanges();
  }

  getMemberLocked(propertyID: string): Observable<any> {
    return this.afs.collection('memberLocked').doc(propertyID).valueChanges();
  }

  checkCodeExistence(propertyID: string): Observable<any> {
    return this.propertiesRef.doc(propertyID).valueChanges();
  }

  searchProperties(params: any): Observable<any> {
    return this.afs.collection(params.collection, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (!this.role || !this.role.includes("ADMIN")) {
        query = query.where('edited', '==', true);
      }
      // if (this.role && !this.role.includes("ADMIN")) {
      //   query = query.where('edited', '==', true);
      // }
      if (params.keywords) { query = query.where('keywords', 'array-contains-any', params.keywords) };
      if (params.state) { query = query.where('state', '==', params.state) };
      if (params.propertyType) { query = query.where('propertyType', '==', params.propertyType) };

      if (params.active) { query = query.where('status', '==', "Active") };
      if (params.privateTreaty) { query = query.where('status', '==', "Private Treaty") };
      if (params.pendingAuction) { query = query.where('status', '==', "Pending Auction") };
      if (params.soonReauction) { query = query.where('status', '==', "Soon Re-Auction") };
      if (params.expired) { query = query.where('status', 'in', ['Expired', 'Called Off']) };

      if (params.bank) { query = query.where('bank', '==', params.bank) };
      if (params.auctionType) { query = query.where('auctionType', '==', params.auctionType) };

      if (params.minDate && params.maxDate) {
        if (params.collection == "properties") {
          if (params.minDate && params.maxDate) { query = query.where('auctionDate', '>=', params.minDate).where('auctionDate', '<=', params.maxDate) };
          if (params.minDate && params.maxDate == null) { query = query.where('auctionDate', '>=', params.minDate) };
          if (params.minDate == null && params.maxDate) { query = query.where('auctionDate', '<=', params.maxDate) };
        } else if (params.collection == "memberLocked") {
          if (params.minDate && params.maxDate) { query = query.where('auctionDate', '>=', params.minDate).where('auctionDate', '<=', params.maxDate) };
          if (params.minDate && params.maxDate == null) { query = query.where('auctionDate', '>=', params.minDate) };
          if (params.minDate == null && params.maxDate) { query = query.where('auctionDate', '<=', params.maxDate) };
        }
      } else if (params.minPrice && params.maxPrice) {
        if (params.minPrice && params.maxPrice) { query = query.where('reservePrice', '>=', params.minPrice).where('reservePrice', '<=', params.maxPrice) };
        if (params.minPrice && params.maxPrice == null) { query = query.where('reservePrice', '>=', params.minPrice) };
        if (params.minPrice == null && params.maxPrice) { query = query.where('reservePrice', '<=', params.maxPrice) };
      } else if (params.minArea && params.maxArea) {
        if (params.minArea && params.maxArea) { query = query.where('landArea', '>=', params.minArea).where('landArea', '<=', params.maxArea) };
        if (params.minArea && params.maxArea == null) { query = query.where('landArea', '>=', params.minArea) };
        if (params.minArea == null && params.maxArea) { query = query.where('landArea', '<=', params.maxArea) };
      } else if (params.minDate || params.maxDate) {
        if (params.collection == "properties") {
          if (params.minDate && params.maxDate) { query = query.where('auctionDate', '>=', params.minDate).where('auctionDate', '<=', params.maxDate) };
          if (params.minDate && params.maxDate == null) { query = query.where('auctionDate', '>=', params.minDate) };
          if (params.minDate == null && params.maxDate) { query = query.where('auctionDate', '<=', params.maxDate) };
        } else if (params.collection == "memberLocked") {
          if (params.minDate && params.maxDate) { query = query.where('auctionDate', '>=', params.minDate).where('auctionDate', '<=', params.maxDate) };
          if (params.minDate && params.maxDate == null) { query = query.where('auctionDate', '>=', params.minDate) };
          if (params.minDate == null && params.maxDate) { query = query.where('auctionDate', '<=', params.maxDate) };
        }
      } else if (params.minPrice || params.maxPrice) {
        if (params.minPrice && params.maxPrice) { query = query.where('reservePrice', '>=', params.minPrice).where('reservePrice', '<=', params.maxPrice) };
        if (params.minPrice && params.maxPrice == null) { query = query.where('reservePrice', '>=', params.minPrice) };
        if (params.minPrice == null && params.maxPrice) { query = query.where('reservePrice', '<=', params.maxPrice) };
      } else if (params.minArea || params.maxArea) {
        if (params.minArea && params.maxArea) { query = query.where('landArea', '>=', params.minArea).where('landArea', '<=', params.maxArea) };
        if (params.minArea && params.maxArea == null) { query = query.where('landArea', '>=', params.minArea) };
        if (params.minArea == null && params.maxArea) { query = query.where('landArea', '<=', params.maxArea) };
      }
      return query;
    }).valueChanges();
  }

  adminUpdateDefaultForm(params: any) {
    const subscription = this.afs.collection('admin').doc(this.uid).valueChanges().subscribe(o => {
      if (o) {
        subscription.unsubscribe();
        const filePath = "defaultForms/" + params.formName;
        const ref = this.afStorage.ref(filePath);
        ref.put(params.file).then(() => {
          window.alert("Form update successful");
          window.location.reload();
        });
      }
    });
  }

  adminDeleteDefaultForm(shortPath: string): Observable<any> {
    const filePath = shortPath;
    const ref = this.afStorage.ref(filePath);
    return ref.delete();
  }

  getForms(folderPath: string): Observable<any> {
    const filePath = folderPath;
    const ref = this.afStorage.ref(filePath);
    return ref.listAll();
  }

  async agentUpdateForms(params: any, file: any, filePath: string) {
    const ref = this.afStorage.ref(filePath);
    await ref.put(file).catch(e => {
      console.log(e);
    });
    params.updated = new Date().getTime();
    this.afs.collection("formSubmission").doc(params.formID).update(params).then(() => {
      window.alert("Form updated");
      window.location.reload();
    }).catch(() => {
      params.created = new Date().getTime();
      params.updated = new Date().getTime();
      this.afs.collection("formSubmission").doc(params.formID).set(params).then(() => {
        window.alert("Form Updated");
        window.location.reload();
      })
    })
  }

  adminGetAllForms() {
    return this.afs.collection("formSubmission").get();
  }

  getAgentForms(agent: string) {
    return this.afs.collection('formSubmission', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('agent', '==', agent);
      return query.orderBy('updated', 'desc');
    }).get();
  }

  getAgentFormsbyID(formID: string) {
    return this.afs.collection("formSubmission").doc(formID).get();
  }

  async agentModifyFormStatus(formID: string, status: string) {
    var params = {
      status: status,
      updated: new Date().getTime()
    }
    if (status == "SUBMITTED") {
      await this.getAgentFormsbyID(formID).toPromise().then(o => {
        const details = o.data();
        var data = {
          dest: "qiwen91@gmail.com",
          formID: formID,
          agent: details.agent,
          buyer: details.buyerName,
          propertyID: details.propertyID,
          status: status,
          title: "New submission by: " + details.agent,
          auctionDate: "",
          venue: "",
          remark: "",
          redirect: "https://homebid.com.my/#/admin-console/" + details.formID
        }
        return this.sendAuctionEmail(data).subscribe();
      })
    }
    return this.afs.collection("formSubmission").doc(formID).update(params)
  }

  async adminModifyFormStatus(formID: string, status: string, approvalParams?: any) {
    var params = {
      status: status,
      updated: new Date().getTime(),
      auctionDate: '',
      auctionVenue: '',
      remark: ''
    };

    if (status == "REJECTED") {
      params.remark = approvalParams.remark

      await this.getAgentFormsbyID(formID).toPromise().then(o => {
        const details = o.data();
        var data = {
          dest: details.agentEmail,
          formID: formID,
          agent: details.agent,
          buyer: details.buyerName,
          propertyID: details.propertyID,
          status: status,
          title: "Submission Rejected",
          auctionDate: "",
          venue: "",
          remark: params.remark,
          redirect: "https://homebid.com.my/#/management-console/" + details.formID
        }
        return this.sendAuctionEmail(data).subscribe();
      })
    } else if (status == "APPROVED") {
      params.auctionDate = approvalParams.auctionDate
      params.auctionVenue = approvalParams.auctionVenue
      params.remark = approvalParams.remark

      await this.getAgentFormsbyID(formID).toPromise().then(o => {
        const details = o.data();
        var data = {
          dest: details.agentEmail,
          formID: formID,
          agent: details.agent,
          buyer: details.buyerName,
          propertyID: details.propertyID,
          status: status,
          title: "Submission Approved",
          auctionDate: "Auction date: " + params.auctionDate,
          venue: "Auction venue: " + params.auctionVenue,
          remark: params.remark,
          redirect: "https://homebid.com.my/#/management-console/" + details.formID
        }
        return this.sendAuctionEmail(data).subscribe();
      })
    } else if (status == "SUCCESSSFUL") {
      await this.getAgentFormsbyID(formID).toPromise().then(o => {
        const details = o.data();
        var data = {
          dest: details.agentEmail,
          formID: formID,
          agent: details.agent,
          buyer: details.buyerName,
          propertyID: details.propertyID,
          status: status,
          title: "Bidding Successful!",
          auctionDate: "",
          venue: "",
          remark: "Congratulations on your successful bid",
          redirect: "https://homebid.com.my/#/management-console/" + details.formID
        }
        return this.sendAuctionEmail(data).subscribe();
      })
    } else if (status == "UNSUCCESSSFUL") {
      await this.getAgentFormsbyID(formID).toPromise().then(o => {
        const details = o.data();
        var data = {
          dest: details.agentEmail,
          formID: formID,
          agent: details.agent,
          buyer: details.buyerName,
          propertyID: details.propertyID,
          status: status,
          title: "Bidding Unsuccessful",
          auctionDate: "",
          venue: "",
          remark: "We would like to inform you that the bidding was unsuccessful",
          redirect: "https://homebid.com.my/#/management-console/" + details.formID
        }
        return this.sendAuctionEmail(data).subscribe();
      })
    } else if (status == "CALLED_OFF") {
      await this.getAgentFormsbyID(formID).toPromise().then(o => {
        const details = o.data();
        var data = {
          dest: details.agentEmail,
          formID: formID,
          agent: details.agent,
          buyer: details.buyerName,
          propertyID: details.propertyID,
          status: status,
          title: "Bidding Called Off",
          auctionDate: "",
          venue: "",
          remark: "We would like to inform you that the bidding was called off",
          redirect: "https://homebid.com.my/#/management-console/" + details.formID
        }
        return this.sendAuctionEmail(data).subscribe();
      })
    }
    return this.afs.collection("formSubmission").doc(formID).update(params)
  }

  getBuyerFormUrl(filePath): Observable<any> {
    let ref = this.afStorage.ref(filePath);
    return ref.getDownloadURL();
  }

  sendAuctionEmail(data: any): Observable<any> {
    const sendAuctionEmail = this.afFunc.httpsCallable('sendAuctionEmail');
    return sendAuctionEmail(data);
  }

  submitBuyingForm(collection: string, params: any) {
    const formID = uuid.v4();
    var enquiryParams = params;

    enquiryParams.formID = formID;

    const submitted = new Date().getTime();
    enquiryParams.submitted = submitted;

    var agentEmail = "hl@homeland.my";
    if (sessionStorage.getItem("agentEmail")) {
      agentEmail = sessionStorage.getItem("agentEmail");
    }

    console.log(enquiryParams);

    this.afs.collection(collection).doc(enquiryParams.formID).set(enquiryParams).then(o => {
      if (agentEmail) {
        this.sendNotificationEmail(collection, agentEmail, enquiryParams).subscribe(() => {
          window.alert("Details sent, please wait for the agent to contact you.")
          window.location.reload();
        });
      }
    });
  }

  sendNotificationEmail(collection: string, agentEmail: string, params: any): Observable<any> {
    const sendEmail = this.afFunc.httpsCallable('sendEmail');
    var data = {
      collection: collection,
      dest: agentEmail,
      buyer: params.fullName,
      buyerEmail: params.email,
      propertyID: params.propertyID,
      formID: params.formID
    }
    return sendEmail(data);
  }

  sendAdminNotificationEmail(productCode: string, transactionId: string): Observable<any> {
    const adminNotificationEmail = this.afFunc.httpsCallable('adminNotificationEmail');
    var data = {
      productCode: productCode,
      transactionId: transactionId,
    }
    return adminNotificationEmail(data);
  }

  async submitSellingForm(params: any, image: any) {
    const formID = uuid.v4();
    var enquiryParams = params;

    enquiryParams.formID = formID;

    const submitted = new Date().getTime();
    enquiryParams.submitted = submitted;

    const filePath = "sellingEnquiry/" + params.formID + "/image0";
    const ref = this.afStorage.ref(filePath);
    await ref.put(image);

    this.afs.collection("sellingEnquiry").doc(enquiryParams.formID).set(enquiryParams).then(o => {
      window.alert("Enquiry form sent, please wait for us to contact you.")
      window.location.reload();
    });
  }

  getBuyingEnquiries(): Observable<any> {
    // return this.afs.collection("buyingEnquiry").valueChanges();
    return this.afs.collection('buyingEnquiry', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (this.role.includes("AUCTIONEER") || this.role.includes("AGENT")) {
        query = query.where('viewBy', '==', this.username)
      };
      query = query.orderBy('submitted', 'desc');
      return query;
    }).valueChanges();
  }

  getBidRegistrations(formID: string): Observable<any> {
    // return this.afs.collection("buyingEnquiry").valueChanges();
    return this.afs.collection('bidRegistration', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (this.role.includes("AUCTIONEER") || this.role.includes("AGENT")) {
        query = query.where('viewBy', '==', this.username)
      };
      if (formID) { query = query.where('formID', '==', formID) };
      query = query.orderBy('submitted', 'desc');
      return query;
    }).valueChanges();
  }

  getSellingEnquiries(): Observable<any> {
    return this.afs.collection('sellingEnquiry', ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.orderBy('submitted', 'desc');
      return query;
    }).valueChanges();
  }

  deleteBuyingEnquiries(formID: string) {
    this.afs.collection("buyingEnquiry").doc(formID).delete();
  }

  async deleteSellingEnquiries(formID: string) {
    const filePath = "sellingEnquiry/" + formID + "/image0"
    let ref = this.afStorage.ref(filePath);
    await ref.delete();
    this.afs.collection("sellingEnquiry").doc(formID).delete();
  }

  adminSearch(params: any): Observable<any> {
    return this.afs.collection("memberLocked", ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (params.username) { query = query.where('lastUpdated', '==', params.username) };
      // if (params.minDate) { query = query.where('lastUpdatedTime', '>=', params.minDate.getTime()) };
      return query;
    }).valueChanges();
  }

  adminUpdatePropCount(params: any): Observable<any> {
    return this.afs.collection("memberLocked", ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (params.state) { query = query.where('state', '==', params.state) };
      query = query.where('edited', '==', true);
      query = query.where('status', '==', "Active");
      return query;
    }).valueChanges();
  }

  getPopup(activated: boolean, archived: boolean): Observable<any> {
    return this.afs.collection("popUp", ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (activated) { query = query.where('activated', '==', true); }
      query = query.where('archived', '==', archived);
      return query.orderBy('updated', 'desc');
    }).valueChanges();
  }

  async adminUploadPopup(params: any, popUpImage) {
    const filePath = "popUp/" + params.uuid + "/image0";
    const ref = this.afStorage.ref(filePath);
    await ref.put(popUpImage);
    params.uploader = this.username;
    this.afs.collection("popUp").doc(params.uuid).set(params);
  }

  adminUpdatePopup(params: any) {
    this.afs.collection("popUp").doc(params.uuid).update(params);
  }

  deleteImage(filePath: string) {
    let ref = this.afStorage.ref(filePath);
    ref.delete();
  }

  deleteProperty(propertyID: string, state: string) {
    this.afs.collection('properties').doc(propertyID).delete().then(() => {
      this.updateTotal(state, false);
      this.afs.collection('memberLocked').doc(propertyID).delete().then(() => {
        const filePath = "properties/" + propertyID + "/image0";
        let ref = this.afStorage.ref(filePath);
        ref.delete();
        this.functionLogger('DELETED PROPERTY: ' + propertyID).subscribe();
        this.router.navigate(['/']);
      });
    });
  }

  updateTotal(state: string, add: boolean) {
    const subscription = this.afs.collection('propertyCount').doc('count').valueChanges().subscribe((o: any) => {
      var totalJson = o;
      subscription.unsubscribe();
      if (add) {
        totalJson[state] = totalJson[state] + 1;
      } else {
        totalJson[state] = totalJson[state] - 1;
      }
      this.afs.collection('propertyCount').doc('count').update(totalJson);
    });
  }

  newsletterAll(params: any): Observable<any> {
    const subscribe = this.afFunc.httpsCallable('newsletterAll');
    return subscribe(params);
  }

  newsletterState(params: any): Observable<any> {
    const subscribe = this.afFunc.httpsCallable('newsletterState');
    return subscribe(params);
  }

  getNewsletter(): Observable<any> {
    return this.afs.collection('newsletter').valueChanges();
  }

  newsletterUpdate(state: string, params: any) {
    this.afs.collection('newsletter').doc(state).update(params);
  }

  getDocs(folderPath: string): Observable<any> {
    const filePath = folderPath;
    const ref = this.afStorage.ref(filePath);
    return ref.listAll();
  }

  agentUpdateDocs(params: any) {
    const filePath = params.filePath;
    const ref = this.afStorage.ref(filePath);
    ref.put(params.file).then(() => {
      window.alert("Form update successful");
      window.location.reload();
    });
  }

  getDocUrl(filePath): Observable<any> {
    let ref = this.afStorage.ref(filePath);
    return ref.getDownloadURL();
  }

  deleteDocs(filePath): Observable<any> {
    let ref = this.afStorage.ref(filePath);
    return ref.delete();
  }

  toKeywords(array: string[]) {
    var keywords = [];
    const ignored = [
      "jalan",
      "sungai",
      "taman",
      "street",
      "lorong",
      "seri",
      "kuala",
      "kampung"
    ];
    var mySet = new Set();
    array.forEach(string => {
      var splitString = string.toString().toLowerCase();
      ignored.forEach(ignore => {
        splitString = splitString.split(ignore).join("");
      });
      splitString = splitString.split(" ").join("|");
      splitString = splitString.split(",").join("|");
      splitString = splitString.split(".").join("|");
      var split = splitString.split("|");
      split.forEach(element => {
        if (element.length >= 4) {
          mySet.add(element);
        }
      });
    })
    mySet.forEach(set => {
      if (set != "") {
        keywords.push(set);
      }
    })
    return keywords;
  }

  removeTime(milli: number): number {
    var date = new Date(milli);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
  }

  resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        let canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        let context = canvas.getContext('2d');

        context.drawImage(image, 0, 0, newWidth, newHeight);

        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

  getDefaultImg(propertyType: string): string {
    const str = propertyType.toLowerCase();
    if (str.includes("apartment") || str.includes("condo")) {
      return "https://urbanparadigm.github.io/storage/homebid-defaults/apartment-condo.png"
    } else if (str.includes("factory")) {
      return "https://urbanparadigm.github.io/storage/homebid-defaults/factory-warehouse.png"
    } else if (str.includes("flat")) {
      return "https://urbanparadigm.github.io/storage/homebid-defaults/flat-low-cost.png"
    } else if (str.includes("land") && !str.includes("landed")) {
      return "https://urbanparadigm.github.io/storage/homebid-defaults/land.png"
    } else if (str.includes("shop") || str.includes("office")) {
      return "https://urbanparadigm.github.io/storage/homebid-defaults/office-shop.png"
    } else if (str.includes("landed")) {
      return "https://urbanparadigm.github.io/storage/homebid-defaults/terrace.png"
    } else if (str.includes("other")) {
      return "https://urbanparadigm.github.io/storage/no-image.png"
    }
    return "https://urbanparadigm.github.io/storage/no-image.png";
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 3000,
    });
  }

}
