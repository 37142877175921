import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseService } from '../services/base.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-form-management',
  templateUrl: './form-management.component.html',
  styleUrls: ['./form-management.component.scss']
})
export class FormManagementComponent implements OnInit {
  buyerList = [];
  buyerFileOpened = false;
  showCreate = true;
  canSubmit = false;
  isApproved = false;
  isAdmin = false;
  userRole = [];
  paths = [];
  selectedIndex = 0;
  formID = "";
  role = "individual";
  defaultForm = null;
  defaultUploadForm = this.fb.group({
    formName: this.fb.control('', [Validators.required])
  });
  buyerForm = this.fb.group({
    buyerName: this.fb.control('', [Validators.required]),
    propertyID: this.fb.control('', [Validators.required])
  });
  requiredDocs = [];

  //approval
  approvalForm = this.fb.group({
    auctionDate: this.fb.control(''),
    auctionVenue: this.fb.control(''),
    remark: this.fb.control(''),
  });

  //filter
  agentSearch: string;
  statusSearch: string;
  allowedStatuses = ["IN_PROGRESS", "DRAFT", "REJECTED"];
  statuses = [
    // { name: "Enquiries", value: "ENQUIRIES" },
    // { name: "Bidding Requests", value: "REQUESTS" },
    { name: "All", value: "ALL" },
    { name: "In Progress", value: "IN_PROGRESS" },
    { name: "Draft", value: "DRAFT" },
    { name: "Submitted", value: "SUBMITTED" },
    { name: "Approved", value: "APPROVED" },
    { name: "Rejected", value: "REJECTED" },
    { name: "Successful cases", value: "SUCCESSFUL" },
    { name: "Unsuccessful cases", value: "UNSUCCESSFUL" },
    { name: "Called off", value: "CALLED_OFF" },
  ]
  allCases = [];
  searchResults = [];
  buyingEnquiries = [];
  bidRegistrations = [];

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.userRole = window.localStorage.getItem("role").split(",");

    if (this.baseService.adminList.includes(this.baseService.username)) {
      this.isAdmin = true;
      this.buyerForm.addControl("buyerAgentName", this.fb.control('', [Validators.required]));
      const buyerAgentName = sessionStorage.getItem("buyerAgentName");
      if (buyerAgentName) {
        this.buyerForm.controls['buyerAgentName'].setValue(buyerAgentName);
      }
    }
    this.getAgentForms();
    this.getDefaultForms();
    this.resetFormUploads();
    this.getBuyingEnquiries();
    this.getBidRegistrations();

    const formID = sessionStorage.getItem("formID");
    if (formID && formID != "NO-FORM") {
      this.formID = formID;
      this.openCase('EXIST', { formID: formID });
    }

    let formStepIndex = window.sessionStorage.getItem("formStepIndex");
    if (formStepIndex) {
      this.selectedIndex = parseInt(formStepIndex);
    }
  }

  defaultUploaded(event: any) {
    this.defaultForm = event.target.files[0];
  }

  uploadDefault(inputTarget: any) {
    if (this.defaultUploadForm.valid && this.defaultForm) {
      var params = this.defaultUploadForm.value;
      params.file = this.defaultForm;
      this.baseService.adminUpdateDefaultForm(params);
    } else {
      window.alert("Incomplete, form not submitted")
    }
    this.defaultForm = null;
    inputTarget.value = "";
  }

  getDefaultForms() {
    const sub = this.baseService.getForms("defaultForms/").subscribe(o => {
      if (o) {
        sub.unsubscribe();
        o.items.forEach(element => {
          var params = {
            shortPath: element.location.path_,
            fullPath: "https://firebasestorage.googleapis.com/v0/b/auction-directory.appspot.com/o/" + encodeURIComponent(element.location.path_) + "?alt=media",
            formName: element.location.path_.replace("defaultForms/", ""),
            description: "",
            show: true
          };
          if (params.formName == "Auction Authorization Letter.pdf") {
            params.description = "(Optional) For authorization of another person to bid on the buyer's behalf."
          } else if (params.formName == "Bidder Registration Form.pdf") {
            params.description = "For registration of bidder."
          } else if (params.formName == "Co-Agency Agreement Auction.pdf") {
            params.description = "For agreement of co-agency."
            if (this.userRole.includes("INVESTOR")) {
              params.show = false;
            }
          } else if (params.formName == "Auction Claim Form.pdf") {
            params.description = "For commission claiming."
            if (this.userRole.includes("INVESTOR")) {
              params.show = false;
            }
          }
          this.paths.push(params);
        });
      }
    })
  }

  getAgentForms() {
    var agent = ""
    if (this.isAdmin) {
      this.baseService.adminGetAllForms().toPromise().then(o => {
        o.docs.map(forms => {
          this.allCases.push(forms.data())
          this.searchResults.push(forms.data())
        })
      })
    } else {
      agent = this.baseService.username;
      this.baseService.getAgentForms(agent).toPromise().then(o => {
        o.docs.map(forms => {
          this.allCases.push(forms.data())
          this.searchResults.push(forms.data())
        })
      })
    }
  }

  getBuyingEnquiries() {
    const sub = this.baseService.getBuyingEnquiries().subscribe(o => {
      sub.unsubscribe();
      this.buyingEnquiries = o;
    })
  }

  getBidRegistrations() {
    const sub = this.baseService.getBidRegistrations(null).subscribe(o => {
      sub.unsubscribe();
      this.bidRegistrations = o;
    })
  }

  openCase(type: string, params?: any) {
    this.resetFormUploads();
    this.showCreate = false;
    this.selectedIndex = 1;

    if (type == "EXIST") {
      this.formID = params.formID;
      this.baseService.getAgentFormsbyID(params.formID).toPromise().then(o => {
        this.buyerForm.controls['buyerName'].setValue(o.data().buyerName);
        this.buyerForm.controls['propertyID'].setValue(o.data().propertyID);
        if (this.isAdmin) {
          this.buyerForm.controls['buyerAgentName'].setValue(params.agent);
        }

        if (this.allowedStatuses.includes(o.data().status)) {
          this.canSubmit = true;
        } else {
          this.canSubmit = false;
        }

        if (o.data().status == "SUBMITTED") {
          const sub = this.baseService.getMemberLocked(o.data().propertyID).subscribe(details => {
            sub.unsubscribe();
            const date = new Date(details.auctionTime);
            const auctionDate = date.toLocaleString("en-GB", { hour12: true });

            this.approvalForm.controls['auctionDate'].setValue(auctionDate);
            this.approvalForm.controls['auctionVenue'].setValue(details.auctionVenue);
          })
        }

        if (o.data().status == "APPROVED") {
          this.isApproved = true;
        } else {
          this.isApproved = false;
        }
      })

      const sub = this.baseService.getForms("formSubmission/" + params.formID).subscribe(o => {
        if (o) {
          sub.unsubscribe();
          o.items.forEach(element => {
            var thePath: string = element.location.path_;
            this.baseService.getBuyerFormUrl(thePath).subscribe(downloadUrl => {
              const lastIndex = thePath.lastIndexOf("/") + 1;
              const formName = thePath.substring(lastIndex);
              this.requiredDocs.forEach(doc => {
                if (doc.value == formName) {
                  const index = this.requiredDocs.indexOf(doc);
                  this.requiredDocs[index].url = downloadUrl;
                }
              });
            })
          })
          this.buyerFileOpened = true;
        }
      })

      sessionStorage.setItem("formStepIndex", "1");

    } else if (type == "CONVERT") {
      this.formID = uuid.v4();
      this.buyerForm.controls['buyerName'].setValue(params.fullName);
      this.buyerForm.controls['propertyID'].setValue(params.propertyID);
      if (this.isAdmin) {
        this.buyerForm.controls['buyerAgentName'].setValue(params.agent);
      }

      this.canSubmit = true;

      this.resetFormUploads();
      this.buyerForm.markAllAsTouched();
      if (this.buyerForm.valid) {
        this.buyerFileOpened = true;
      }

      sessionStorage.setItem("formStepIndex", "1");

    } else if (type == "NEW") {
      this.formID = uuid.v4();

      this.resetFormUploads();
      this.buyerForm.markAllAsTouched();
      if (this.buyerForm.valid) {
        this.canSubmit = true;
        this.buyerFileOpened = true;
      } else {
        this.showCreate = true;
      }

      this.selectedIndex = 1;
    }

  }

  updateBuyerForm(docName: string, event: any) {
    if (this.buyerForm.valid) {
      const agent = this.baseService.username;
      const agentEmail = this.baseService.email;
      var params = this.buyerForm.value;
      params.agent = agent;
      params.agentEmail = agentEmail;
      params[docName] = "SUBMITTED";
      params.formID = this.formID;
      params.status = "IN_PROGRESS"
      var filePath = "formSubmission/" + params.formID + "/" + docName;
      var file = event.target.files[0];
      sessionStorage.setItem("formID", this.formID);
      sessionStorage.setItem("formStepIndex", "1");
      this.baseService.agentUpdateForms(params, file, filePath);
    }
  }

  saveAsDraft() {
    this.baseService.agentModifyFormStatus(this.formID, "DRAFT").then(() => {
      window.sessionStorage.removeItem("formID");
      sessionStorage.setItem("formStepIndex", "3");
      window.alert("Saved as draft");
      window.location.reload();
    });
  }

  submitCase() {
    this.baseService.agentModifyFormStatus(this.formID, "SUBMITTED").then(() => {
      window.sessionStorage.removeItem("formID");
      window.alert("Submission successful");
      window.location.reload();
    });
  }

  rejectCase() {
    const remark = this.approvalForm.value.remark;
    if (remark) {
      this.baseService.adminModifyFormStatus(this.formID, "REJECTED", this.approvalForm.value).then(() => {
        window.sessionStorage.removeItem("formID");
        window.alert("Submission rejected");
        window.location.reload();
      })
    }
  }

  approveCase() {
    const auctionDate = this.approvalForm.value.auctionDate;
    const auctionVenue = this.approvalForm.value.auctionVenue;
    if (auctionDate && auctionVenue) {
      this.baseService.adminModifyFormStatus(this.formID, "APPROVED", this.approvalForm.value).then(() => {
        window.sessionStorage.removeItem("formID");
        window.alert("Submission approved");
        window.location.reload();
      })
    }
  }

  successfulCase() {
    this.baseService.adminModifyFormStatus(this.formID, "SUCCESSFUL").then(() => {
      window.sessionStorage.removeItem("formID");
      window.alert("Bidding successful!");
      window.location.reload();
    })
  }

  unsuccessfulCase() {
    this.baseService.adminModifyFormStatus(this.formID, "UNSUCCESSFUL").then(() => {
      window.sessionStorage.removeItem("formID");
      window.alert("Bidding unsuccessful");
      window.location.reload();
    })
  }

  calledOff() {
    this.baseService.adminModifyFormStatus(this.formID, "CALLED_OFF").then(() => {
      window.sessionStorage.removeItem("formID");
      window.alert("Called off");
      window.location.reload();
    })
  }

  roleChanged(value: any) {
    this.role = value;
    this.resetFormUploads();
  }

  searchByAgent() {
    if (this.agentSearch != "") {
      const searchResult = this.allCases.filter(caseDetails => caseDetails.agent.toLowerCase().includes(this.agentSearch));
      this.searchResults = searchResult;
    } else {
      this.searchResults = this.allCases;
    }
  }

  searchByStatus(status) {
    if (status == "ALL") {
      this.searchResults = this.allCases;
    } else if (status == "ENQUIRIES") {
      this.searchResults = this.buyingEnquiries;
    } else if (status == "REQUESTS") {
      this.searchResults = this.bidRegistrations;
    } else {
      const searchResult = this.allCases.filter(caseDetails => caseDetails.status == status);
      this.searchResults = searchResult;
    }
  }

  deleteForm(shortPath: string) {
    this.baseService.adminDeleteDefaultForm(shortPath).subscribe(() => {
      window.location.reload();
    })
  }

  resetFormUploads() {
    const buyerRequiredDocs = [
      {
        name: "Bidder registration form",
        value: "bidderRegistrationForm",
        description: "For registration of bidder.",
        url: null
      },
      {
        name: "Auction authorization letter",
        value: "authorisationLetter",
        description: "(Optional) For authorization of another person to bid on the buyer's behalf.",
        url: null
      },
      {
        name: "Bidder NRIC",
        value: "bidderIc",
        description: "Bidder's NRIC (front and back).",
        url: null
      },
      {
        name: "Authorised bidder NRIC",
        value: "authorisedBidderIc",
        description: "NRIC of the authorised bidder (front and back).",
        url: null
      },
      {
        name: "Proof of payment",
        value: "proofOfPayment",
        description: "Payment proof for deposit, can be bank transfer, cheque, etc.",
        url: null
      },
    ]

    const companyRequiredDocs = [
      {
        name: "Bidder registration form",
        value: "bidderRegistrationForm",
        description: "For registration of bidder.",
        url: null
      },
      {
        name: "Auction authorization letter",
        value: "authorisationLetter",
        description: "(Optional) For authorization of another person to bid on the buyer's behalf.",
        url: null
      },
      {
        name: "Bidder NRIC",
        value: "bidderIc",
        description: "Bidder's NRIC (front and back).",
        url: null
      },
      {
        name: "Authorised bidder NRIC",
        value: "authorisedBidderIc",
        description: "NRIC of the authorised bidder (front and back).",
        url: null
      },
      {
        name: "Board of Directors' Resolution",
        value: "boardOfDirectorsResolution",
        description: "Board of Directors' Resolution.",
        url: null
      },
      {
        name: "Form 24",
        value: "form24",
        description: "Form 24.",
        url: null
      },
      {
        name: "Form 44",
        value: "form44",
        description: "Form 44.",
        url: null
      },
      {
        name: "Form 49 (or equivalent)",
        value: "form49",
        description: "Form 49 or equivalent.",
        url: null
      },
      {
        name: "M&A",
        value: "mAndA",
        description: "M and A.",
        url: null
      },
      {
        name: "Proof of payment",
        value: "proofOfPayment",
        description: "Payment proof for deposit, can be bank transfer, cheque, etc.",
        url: null
      },
    ]

    if (this.userRole.includes("AGENT")) {
      buyerRequiredDocs.push({
        name: "Co-agency agreement auction",
        value: "coAgencyLetter",
        description: "For agreement of co-agency.",
        url: null
      })
      buyerRequiredDocs.push({
        name: "Auction claim form",
        value: "claimForm",
        description: "For commission claiming.",
        url: null
      })
      buyerRequiredDocs.push({
        name: "Agent NRIC",
        value: "agentIc",
        description: "Agent's NRIC (front and back).",
        url: null
      })

      companyRequiredDocs.push({
        name: "Co-agency agreement auction",
        value: "coAgencyLetter",
        description: "For agreement of co-agency.",
        url: null
      })
      companyRequiredDocs.push({
        name: "Auction claim form",
        value: "claimForm",
        description: "For commission claiming.",
        url: null
      })
      companyRequiredDocs.push({
        name: "Agent NRIC",
        value: "agentIc",
        description: "Agent's NRIC (front and back).",
        url: null
      })
    }

    if (this.role == "individual") {
      this.requiredDocs = buyerRequiredDocs;
    } else if (this.role == "company") {
      this.requiredDocs = companyRequiredDocs;
    }

  }

  selectionChange(event) {
    this.selectedIndex = event.selectedIndex;
  }

  @Input() set propertyID(id: string) {
    if (id) {
      this.buyerForm.controls['propertyID'].setValue(id);
    }
  }

  getStatusName(value: string) {
    var name = "NOT_A_STATUS";
    this.statuses.forEach(status => {
      if (status.value == value) {
        name = status.name;
      }
    })
    return name;
  }

}