<form class="row my-2 d-flex" [formGroup]="calculatorForm">
    <div class="col-12 col-sm-6 col-xl-3">
        <mat-form-field color="accent">
            <mat-label>Property Price (RM)</mat-label>
            <input formControlName="propPrice" matInput type="number" (focusout)="onChange()" />
        </mat-form-field>
    </div>
    <div class="col-12 col-sm-6 col-xl-3">
        <mat-form-field color="accent">
            <mat-label>Down Payment (%)</mat-label>
            <input formControlName="downPayment" matInput type="number" min=0 max=100 (focusout)="onChange()" />
        </mat-form-field>
    </div>
    <div class="col-12 col-sm-6 col-xl-3">
        <mat-form-field color="accent">
            <mat-label>Annual Interest Rate (%)</mat-label>
            <input formControlName="interestRate" matInput type="number" min=0 max=100 (focusout)="onChange()" />
        </mat-form-field>
    </div>
    <div class="col-12 col-sm-6 col-xl-3">
        <mat-form-field color="accent">
            <mat-label>Loan Term (year)</mat-label>
            <input formControlName="loanTerm" matInput type="number" (focusout)="onChange()" />
        </mat-form-field>
    </div>
    <div class="col-12 col-sm-6 col-xl-3">
        <mat-form-field color="accent">
            <mat-label>Extra payment (monthly)</mat-label>
            <input formControlName="monthlyExtra" matInput type="number" (focusout)="onChange()" />
        </mat-form-field>
    </div>
</form>

<div class="row my-2">
    <div class="mat-headline col-12">Summary</div>
    <div class="mat-subheading-2 col-6">Estimated monthly payment</div>
    <div class="mat-subheading-1 col-6 color-red" style="font-weight: bold;">
        RM{{ monthlyPayment }}/month
    </div>

    <div class="mat-subheading-2 col-6">Loan amount</div>
    <div class="mat-subheading-1 col-6">RM{{ loanAmount }}</div>

    <div class="mat-subheading-2 col-6">Total payment</div>
    <div class="mat-subheading-1 col-6">RM{{ totalPayment }}</div>

    <div class="mat-subheading-2 col-6">Total interest</div>
    <div class="mat-subheading-1 col-6">RM{{ totalInterest }}</div>

    <div class="mat-title col-12">First Month</div>
    <div class="col-8">
        <mat-progress-bar mode="determinate" [value]="progressBar"></mat-progress-bar>
    </div>

    <div class="mat-subheading-2 col-6">Starting interest payment</div>
    <div class="mat-subheading-1 col-6 accent-main">RM{{ firstInterest }}</div>

    <div class="mat-subheading-2 col-6">Starting principal payment</div>
    <div class="mat-subheading-1 col-6 accent-sub">RM{{ firstPrincipal }}</div>

    <div class="mat-title col-12">With Extra Payment</div>
    <div class="mat-subheading-2 col-6">Estimated time saved</div>
    <div class="mat-subheading-1 col-6 color-green">
        {{ yearsSaved }} years {{ monthsSaved }} months ({{
        totalMonthsSaved
      }}
        months)
    </div>

    <div class="mat-subheading-2 col-6">Estimated interest saved</div>
    <div class="mat-subheading-1 col-6 color-green">RM{{ interestSaved }}</div>
</div>

<mat-expansion-panel *ngIf="medium">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Full loan status
        </mat-panel-title>
    </mat-expansion-panel-header>

    <!-- <button class="col-2 my-1 " mat-button (click)="generate()">Generate</button> -->

    <div class="row d-flex justify-content-center">
        <div class="mat-subheading-2 col-6" style="text-align: center;"></div>
        <table class="table col-8">
            <thead>
                <tr>
                    <th scope="col">Month No.</th>
                    <th scope="col">Interest Accrued (RM)</th>
                    <th scope="col">Pre-payment Balance (RM)</th>
                    <th scope="col">Payment (RM)</th>
                    <th scope="col">Loan Balance (RM)</th>
                    <th scope="col">Principal (RM)</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of content">
                    <td *ngFor="let column of row">{{ column }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</mat-expansion-panel>