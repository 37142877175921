<mat-toolbar class="top-nav-background mat-elevation-z2" style="height: 100%;">
    <button mat-flat-button class="title nav-item mr-5" routerLink="" (click)="clearSearch()">
        <img class="logo" src="https://urbanparadigm.github.io/storage/homebid_logo8.png">
    </button>
    <button *ngIf="!handset" mat-flat-button class="title nav-item" [matMenuTriggerFor]="listingMenu">
        Listing
        <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <button *ngIf="!handset" mat-flat-button class="title nav-item" [matMenuTriggerFor]="auctionMenu">
        Auction
        <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <button *ngIf="!handset" mat-flat-button class="title nav-item" routerLink="events">
        Events & News
    </button>
    <button *ngIf="!handset" mat-flat-button class="title nav-item" routerLink="/our-services/services">
        Our Services
    </button>
    <span class="center-remaining-space"></span>
    <ng-container *ngIf="!handset; else elseHandset">
        <ng-container *ngIf="loggedIn; else notLogged">
            <button mat-flat-button class="title nav-item" [matMenuTriggerFor]="userMenu">
                {{ username }}
                <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <button mat-flat-button class="title nav-item" (click)="onLogOut()">Log Out</button>
        </ng-container>
        <ng-template #notLogged>
            <button mat-flat-button class="title nav-item" [matMenuTriggerFor]="registrationRoles">
                Register
                <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <div>
                <button mat-flat-button class="title nav-item" (click)="showLoginDialog()">Login</button>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #elseHandset>
        <button mat-flat-button class="title nav-item" [matMenuTriggerFor]="handsetMenu">
            <mat-icon>menu_open</mat-icon>
        </button>
    </ng-template>
</mat-toolbar>
<mat-menu #userMenu>
    <button mat-menu-item routerLink="/user-profile">
        Profile
    </button>
    <div *ngIf="isMember && !isAdmin">
        <mat-divider></mat-divider>
        <p class="px-3 m-0 shareProfile">Share Profile:</p>
        <button mat-menu-item (click)="shareContact('whatsapp')">
            Whatsapp
        </button>
        <button mat-menu-item (click)="shareContact('facebook')">
            Facebook
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/management-console">
            Submissions
        </button>
        <!-- <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/subscription">
            Join us 
        </button> -->
    </div>
    <div *ngIf="isAdmin">
        <button mat-menu-item routerLink="/upload-property">
            Upload property
        </button>
        <button mat-menu-item routerLink="/agent-list">
            Agent list
        </button>
        <button mat-menu-item routerLink="/admin-console">
            Manage
        </button>
        <button mat-menu-item routerLink="/admin-subscription">
            Subscriptions
        </button>
    </div>
</mat-menu>
<mat-menu #handsetMenu>
    <ng-container *ngIf="loggedIn; else notLogged">
        <button mat-menu-item routerLink="/user-profile">
            {{ username }}
        </button>
        <mat-divider></mat-divider>
        <!-- <h3 class="px-3 m-0">Auctions:</h3>
        <button mat-menu-item routerLink="/private-auction-buy">
            Buyer Benefits
        </button>
        <button mat-menu-item routerLink="/private-auction-sell">
            Seller Benefits
        </button>
        <mat-divider></mat-divider> -->
        <div *ngIf="isMember && !isAdmin">
            <mat-divider></mat-divider>
            <p class="px-3 m-0 shareProfile">Share Profile:</p>
            <button mat-menu-item (click)="shareContact('whatsapp')">
                Whatsapp
            </button>
            <button mat-menu-item (click)="shareContact('facebook')">
                Facebook
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item routerLink="/events">
                Events & News
            </button>
            <button mat-menu-item routerLink="/our-services/services">
                Our Services
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item routerLink="/management-console">
                Submissions
            </button>
            <!-- <mat-divider></mat-divider>
            <button mat-menu-item routerLink="/subscription">
                Join us 
            </button> -->
        </div>
        <div *ngIf="isAdmin">
            <button mat-menu-item routerLink="/upload-property">
                Upload property
            </button>
            <button mat-menu-item routerLink="/agent-list">
                Agent list
            </button>
            <button mat-menu-item routerLink="/admin-console">
                Manage
            </button>
            <button mat-menu-item routerLink="/admin-subscription">
                Subscriptions
            </button>
        </div>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="onLogOut()">Log Out</button>
    </ng-container>
    <ng-template #notLogged>
        <h3 class="px-3 m-0">Register:</h3>
        <button mat-menu-item routerLink="/sign-up/AGENT">
            As an agent
        </button>
        <button mat-menu-item routerLink="/sign-up/INVESTOR">
            As an investor
        </button>
        <mat-divider></mat-divider>
        <!-- <h3 class="px-3 m-0">Auctions:</h3>
        <button mat-menu-item routerLink="/private-auction-buy">
            Buyer benefits
        </button>
        <button mat-menu-item routerLink="/private-auction-sell">
            Seller benefits
        </button>
        <mat-divider></mat-divider> -->
        <button mat-menu-item routerLink="/events">
            Events & News
        </button>
        <button mat-menu-item routerLink="/our-services/services">
            Our Services
        </button>
        <mat-divider></mat-divider>
        <div>
            <button mat-menu-item (click)="showLoginDialog()">Login</button>
        </div>
    </ng-template>
</mat-menu>

<mat-menu #auctionMenu>
    <button mat-menu-item routerLink="" (click)="clearSearch()">
        Bank Auctions
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item mat-menu-item routerLink="/tutorial">
        How to bid
    </button>
</mat-menu>

<mat-menu #privateAuction>
    <button mat-menu-item routerLink="/private-auction-buy">
        Buyer Benefits
    </button>
    <button mat-menu-item routerLink="/private-auction-sell">
        Seller Benefits
    </button>
</mat-menu>

<mat-menu #registrationRoles>
    <button mat-menu-item routerLink="/sign-up/AGENT">
        As an agent
    </button>
    <button mat-menu-item routerLink="/sign-up/INVESTOR">
        As an investor
    </button>
</mat-menu>

<mat-menu #listingMenu>
    <a mat-menu-item [matMenuTriggerFor]="residential">
        Residential
    </a>
    <mat-divider></mat-divider>
    <a mat-menu-item [matMenuTriggerFor]="commercial">
        Commercial
    </a>
</mat-menu>

<mat-menu #residential>
    <button mat-menu-item (click)="search('Apartment')">
        Apartment
    </button>
    <button mat-menu-item (click)="search('Flat')">
        Flat
    </button>
    <button mat-menu-item (click)="search('Low Cost')">
        Low Cost
    </button>
    <button mat-menu-item (click)="search('Condominium')">
        Condominium
    </button>
    <button mat-menu-item (click)="search('Single Storey')">
        Single Storey
    </button>
    <button mat-menu-item (click)="search('Double Storey')">
        Double Storey
    </button>
    <button mat-menu-item (click)="search('Semi Detached')">
        Semi Detached
    </button>
    <button mat-menu-item (click)="search('Bungalow')">
        Bungalow
    </button>
</mat-menu>

<mat-menu #commercial>
    <button mat-menu-item (click)="search('Shop')">
        Commercial / Shop
    </button>
    <button mat-menu-item (click)="search('Factory')">
        Industrial / Factory
    </button>
    <button mat-menu-item (click)="search(' Land ')">
        Land
    </button>
</mat-menu>