import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyCountCardComponent } from './property-count-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [PropertyCountCardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule
  ],
  exports: [
    PropertyCountCardComponent,
  ]
})
export class PropertyCountCardModule { }
