import { Component, OnInit, Input } from '@angular/core';
import { BaseService } from '../services/base.service';
import { Router } from '@angular/router';
import { faFacebook, faInstagram, faFacebookMessenger, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss']
})
export class PropertyCardComponent implements OnInit {
  @Input() notFeatured: boolean;
  @Input() index: number;
  @Input() listView: boolean;
  @Input() status: string;
  @Input() propertyID: any;
  @Input() propertyName: any;
  @Input() reservePrice: any;
  @Input() auctionDate: any;
  @Input() auctionTime: any;
  @Input() imgURL: any;
  @Input() landArea: any;
  @Input() tenure: any;
  @Input() streetNumber: any;
  @Input() route: any;
  @Input() city: any;
  @Input() postcode: any;
  @Input() state: any;
  @Input() propertyType: any;
  @Input() nthAuction: any;
  @Input() auctionType: any;
  @Input() posUUID: any;
  propertyArea = "";
  // @Input() favourited: any;
  favourited: any;
  featured: any;
  isAdmin = false;
  isMember = false;
  isAgent = false;
  pos: any;
  agentName = "Homeland"
  whatsappHRef = "https://wa.me/60174059268?text=I%20am%20interested%20in%20";
  phoneNumber = "60174059268";
  messengerHRef = "http://m.me/auctionandprivatebidding";
  facebookName = "auctionandprivatebidding";
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faFacebookMessenger = faFacebookMessenger;
  faWhatsapp = faWhatsapp;
  noImage = false;

  constructor(
    private router: Router,
    private baseService: BaseService,
  ) { }

  ngOnInit(): void {
    if (this.baseService.favourite.includes(this.propertyID)) {
      this.favourited = true;
    }
    if (this.baseService.featured.includes(this.propertyID)) {
      this.featured = true;
    }

    setTimeout(() => {
      const phoneNumber = sessionStorage.getItem('phoneNumber');
      if (phoneNumber) {
        this.phoneNumber = phoneNumber;
      } else if (localStorage.getItem('phoneNumber')) {
        this.phoneNumber = localStorage.getItem('phoneNumber');
      }
      if(sessionStorage.getItem('fullName')) {
        this.agentName = sessionStorage.getItem('fullName');
      }
      var text = "Hi " + this.agentName + ", I found your property (https://www.homebid.com.my/%23/property-detail/" + this.propertyID + ") on homebid.com.my and am interested to find out more."
      this.whatsappHRef = "https://wa.me/" + this.phoneNumber + "?text=" + text;

      const facebookName = sessionStorage.getItem('facebookName');
      if (facebookName) {
        this.facebookName = facebookName;
      } else if (localStorage.getItem('facebookName')) {
        this.facebookName = localStorage.getItem('facebookName');
      }
      this.messengerHRef = "http://m.me/" + this.facebookName;
    }, 500);


    this.propertyArea = this.city.replace(",", "");

    if (this.streetNumber != null) {
      this.streetNumber = this.streetNumber + ",";
    }
    if (this.route != null) {
      this.route = this.route + ",";
    }
    if (this.city != null) {
      this.city = this.city + ",";
    }
    if (this.postcode != null) {
      this.postcode = this.postcode + ",";
    }
    // if (this.imgURL == "") {
    //   this.baseService.getPropertyStorage(this.propertyID).subscribe(o => {
    //     if (o) {
    //       this.imgURL = o;
    //     }
    //   })
    // }
    this.baseService.getImageCount({ propertyID: this.propertyID }).subscribe(o => {
      const server = this.baseService.server;
      if (o.status == 'OK' && o.result > 0) {
        this.imgURL = "https://firebasestorage.googleapis.com/v0/b/auction-directory.appspot.com/o/properties%2F" + this.propertyID + "%2Fimage0?alt=media";
      } else {
        this.imgURL = this.baseService.getDefaultImg(this.propertyType);
        this.noImage = true;
      }
    })

    if (this.baseService.username) {
      this.pos = "https://firebasestorage.googleapis.com/v0/b/auction-directory.appspot.com/o/pos%2F" + this.posUUID + "?alt=media"
    } else {
      this.pos = "#/sign-up/AGENT"
    }
    const role = localStorage.getItem("role");
    if (role && role.includes("MEMBER")) {
      this.isMember = true;
      if (role && (role.includes("AGENT") || role.includes("AUCTIONEER"))) {
        this.isAgent = true;
      } else if (role.includes("ADMIN")) {
        this.isAdmin = true;
        this.baseService.getGeneralFeatured().subscribe(o => {
          if (o && o.includes(this.propertyID)) {
            this.featured = true;
          }
        })
      }
    }
  }

  toggleFavourite() {
    const username = localStorage.getItem("username")
    const favouriteSub = this.baseService.getUserList(username, false).subscribe(o => {
      favouriteSub.unsubscribe();
      var favouritedArray = [];
      if (o.favourite) {
        favouritedArray = o.favourite;
      }
      const index = favouritedArray.indexOf(this.propertyID);
      if (this.favourited) {
        favouritedArray.splice(index, 1);
      } else {
        favouritedArray.push(this.propertyID);
      }
      const params = {
        username: username,
        favourite: favouritedArray
      }
      this.baseService.toggleFavourite(params);
      this.favourited = !this.favourited;
    })
  }

  toggleFeatured() {    
    if (this.isAgent) {
      const username = localStorage.getItem("username")
      const featuredSub = this.baseService.getUserList(username, false).subscribe(o => {
        featuredSub.unsubscribe();
        var featuredArray = [];
        if (o.featured) {
          featuredArray = o.featured;
        }
        const index = featuredArray.indexOf(this.propertyID);
        if (this.featured) {
          featuredArray.splice(index, 1);
        } else {
          featuredArray.push(this.propertyID);
        }
        const params = {
          username: username,
          featured: featuredArray
        }
        this.baseService.toggleFeatured(params);
        this.featured = !this.featured;
      })
    } else if (this.isAdmin) {
      const getFeat = this.baseService.getGeneralFeatured().subscribe(o => {
        getFeat.unsubscribe();
        var featuredArray = o.featured;
        const index = featuredArray.indexOf(this.propertyID);
        if (index == -1) {
          featuredArray.push(this.propertyID);
        } else {
          featuredArray.splice(index, 1);
        }
        const params = {
          featured: featuredArray
        }
        this.baseService.toggleGeneralFeatured(params);
        this.featured = !this.featured;
      })
    }
  }

  navigate() {
    if (this.isAdmin) {
      const url = window.location.origin + "/#/property-detail/" + this.propertyID
      window.open(url, '_blank');
    } else {
      this.router.navigate(['/', 'property-detail', this.propertyID]);
    }
  }

  getOrdinal(nth: string) {
    switch (nth) {
      case "1":
        return "1st";

      case "2":
        return "2nd";
        
      default:
        return nth + "th";
    }
  }

}
