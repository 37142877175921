<!-- <app-search-properties [state]="state" [total]="total" (searched)="onSearched($event)"></app-search-properties> -->
<div class="container py-2 mb-5">
    <div class="row justify-content-center py-3">
        <!-- <div class="col-12 col-md-6 mb-3">
            <app-auction-calendar (searched)="onSearched($event)"></app-auction-calendar>
        </div> -->
        <div class="col-12 mb-3">
            <!-- <app-banner-carousel></app-banner-carousel> -->
            <ngb-carousel>
                <ng-template ngbSlide>
                    <a href="https://homebid.com.my/#/sign-up/AGENT">
                        <img class="banner" src="https://urbanparadigm.github.io/storage/homebid-main-poster-3.png"
                            alt="Main poster 1">
                    </a>
                    <div class="carousel-caption">
                        <!-- <h3>Main Poster 1</h3> -->
                        <p></p>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
        <div class="col-12 mt-5">
            <form id="searchForm">
                <input [(ngModel)]="keyword" placeholder="Keyword  (e.g.: double storey, Air Itam, Penang)" id="search" name="keywordSearch" />
            </form>
            <button mat-flat-button id="search-btn" form="searchForm" (click)="keywordSearch()">
                <mat-icon>search</mat-icon> Search
            </button>
        </div>
        <!-- <div *ngIf="agentName" class="col-12 col-md-9 py-3">
            <app-contact-card [agentName]="agentName"></app-contact-card>
        </div> -->
        <div *ngIf="featuredProperties.length != 0 && showFeatured" class="col-12 mt-5">
            <mat-card class="mat-elevation-z8 primary-text-bg pb-4">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="mat-display-1 mb-4 mt-2 bold-text">Featured Properties</div>
                    </div>
                    <ng-container *ngIf="!handset && featuredProperties.length<4; else useCarousel">
                        <div class="col-6 col-md-3" *ngFor="let property of featuredProperties; let i = index">
                            <app-property-card [notFeatured]="notFeatured" [index]="i" [status]="property.status"
                                [propertyID]="property.propertyID" [propertyName]="property.propertyName"
                                [reservePrice]="property.reservePrice" [auctionDate]="property.auctionDate"
                                [landArea]="property.landArea" [tenure]="property.tenure"
                                [streetNumber]="property.streetNumber" [route]="property.route" [city]="property.city"
                                [postcode]="property.postcode" [state]="property.state"
                                [propertyType]="property.propertyType" [nthAuction]="property.nthAuction"
                                [auctionType]="property.auctionType" [posUUID]="property.posUUID"
                                [favourited]="property.favourited">
                            </app-property-card>
                        </div>
                    </ng-container>
                    <ng-template #useCarousel>
                        <div class="col-12">
                            <app-featured-carousel #carousel [propertyDetails]="featuredProperties">
                            </app-featured-carousel>
                        </div>
                    </ng-template>

                </div>
            </mat-card>
        </div>
        <!-- <div *ngIf="myProperties.length != 0" class="col-12 py-3">
            <mat-card class="mat-elevation-z8">
                <div class="row">
                    <div class="col-12">
                        <h2>Properties of {{ agentName }}</h2>
                    </div>
                    <div class="col-12">
                        <div class="row d-flex align-items-stretch">
                            <div *ngFor="let property of myProperties; let i = index"
                                [ngClass]="listView? 'col-12 my-2' : 'col-12 col-sm-6 col-lg-3 my-2'">
                                <app-property-card [notFeatured]="notFeatured" [index]="i" [status]="property.status"
                                    [propertyID]="property.propertyID" [propertyName]="property.propertyName"
                                    [reservePrice]="property.reservePrice" [auctionDate]="property.auctionDate"
                                    [landArea]="property.landArea" [tenure]="property.tenure"
                                    [streetNumber]="property.streetNumber" [route]="property.route"
                                    [city]="property.city" [postcode]="property.postcode" [state]="property.state"
                                    [propertyType]="property.propertyType" [nthAuction]="property.nthAuction"
                                    [auctionType]="property.auctionType" [posUUID]="property.posUUID"
                                    [favourited]="property.favourited">
                                </app-property-card>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div> -->
    </div>

    <!-- <div id="propertiesList" class="row justify-content-center">
        <div class="col-12 col-lg-9">
            <ng-container *ngTemplateOutlet="page"></ng-container>
        </div>
        <div class="col-12 col-lg-3">
            <app-property-count-card (searched)="onSearched($event)" (setState)="setState($event)"
                [propertyCounts]="propertyCounts" [total]="total"></app-property-count-card>
        </div>
    </div> -->

    <div class="d-flex justify-content-between">
        <div class="mat-display-1 bold-text mb-4 mt-5">Latest Properties</div>
        <div class="mat-h3 mt-5 see-all" (click)="keywordSearch()">See all
            <mat-icon style="vertical-align: middle;">arrow_forward</mat-icon>
        </div>
    </div>
    <div class="row d-flex align-items-stretch p-2">
        <div *ngFor="let property of properties[pageNumber]; let i = index" class="col-6 col-sm-6 col-lg-3 p-2">
            <app-property-card [notFeatured]="notFeatured" [index]="i" [status]="property.status"
                [propertyID]="property.propertyID" [propertyName]="property.propertyName"
                [reservePrice]="property.reservePrice" [auctionDate]="property.auctionDate"
                [landArea]="property.landArea" [tenure]="property.tenure" [streetNumber]="property.streetNumber"
                [route]="property.route" [city]="property.city" [postcode]="property.postcode" [state]="property.state"
                [propertyType]="property.propertyType" [nthAuction]="property.nthAuction"
                [auctionType]="property.auctionType" [posUUID]="property.posUUID" [favourited]="property.favourited">
            </app-property-card>
        </div>
    </div>
    <!-- <div class="col-12">
        <ng-container *ngTemplateOutlet="page"></ng-container>
    </div> -->
</div>
<ng-template #page>
    <p class="pagination">
        Page {{ pageNumber + 1 }} of {{ pages }} (Search results: {{ results }})
    </p>
    <p>
        <mat-icon class="clickable" (click)="changePage(false, true)">first_page</mat-icon>
        <mat-icon class="clickable" (click)="changePage(false, false)">keyboard_arrow_left</mat-icon>
        <mat-icon class="clickable" (click)="changePage(true, false)">keyboard_arrow_right</mat-icon>
        <mat-icon class="clickable" (click)="changePage(true, true)">last_page</mat-icon>
    </p>
</ng-template>