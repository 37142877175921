import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PropertyCardRoutingModule } from './property-card-routing.module';
import { PropertyCardComponent } from './property-card.component';
import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [PropertyCardComponent],
  imports: [
    CommonModule,
    PropertyCardRoutingModule,
    MatCardModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    FontAwesomeModule
  ],
  exports: [
    PropertyCardComponent,
  ]
})
export class PropertyCardModule { }
