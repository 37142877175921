<div class="container-fluid">
    <div class="row">
        <div *ngIf="advanced" class="col-12 primary-text-bg">
            <form id="advancedSearch" [formGroup]="advancedSearchForm">
                <div class="container-fluid">
                    <div class="row d-flex align-items-center">
                        <div class="col-12 col-lg-2">
                            <mat-checkbox formControlName="active">Active</mat-checkbox>
                        </div>
                        <div class="col-12 col-lg-2">
                            <mat-checkbox formControlName="privateTreaty">Private Treaty</mat-checkbox>
                        </div>
                        <!-- <div class="col-12 col-lg-2">
                            <mat-checkbox formControlName="pendingAuction">Pending</mat-checkbox>
                        </div>
                        <div class="col-12 col-lg-2">
                            <mat-checkbox formControlName="soonReauction">Re-auction</mat-checkbox>
                        </div> -->
                        <div class="col-12 col-lg-2">
                            <mat-checkbox formControlName="expired" [(ngModel)]="expired">Expired</mat-checkbox>
                        </div>
                        <div class="col-12 col-lg-2">
                            <ng-container *ngIf="propertyTypes | async as propertyTypes">
                                <mat-form-field color="accent">
                                    <mat-label>Property Type</mat-label>
                                    <mat-select formControlName="propertyType">
                                        <mat-option *ngFor="let propertyType of propertyTypes" [value]="propertyType">
                                            {{propertyType}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <div class="col-12 col-lg-2">
                            <mat-form-field color="accent">
                                <mat-label>Min. Area (sqft.)</mat-label>
                                <input formControlName="minArea" matInput type="number" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-2">
                            <mat-form-field color="accent">
                                <mat-label>Max. Area (sqft.)</mat-label>
                                <input formControlName="maxArea" matInput type="number" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-2">
                            <mat-form-field color="accent">
                                <mat-label>From</mat-label>
                                <input formControlName="minDate" matInput (click)="minPicker.open()"
                                    [ngxMatDatetimePicker]="minPicker" readonly>
                                <ngx-mat-datetime-picker #minPicker [showSeconds]="showSeconds"
                                    [stepMinute]="stepMinute" [defaultTime]="minDefaultTime" [enableMeridian]="true">
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-2">
                            <mat-form-field color="accent">
                                <mat-label>To</mat-label>
                                <input formControlName="maxDate" matInput (click)="maxPicker.open()"
                                    [ngxMatDatetimePicker]="maxPicker" readonly>
                                <ngx-mat-datetime-picker #maxPicker [showSeconds]="showSeconds"
                                    [stepMinute]="stepMinute" [defaultTime]="maxDefaultTime" [enableMeridian]="true">
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-2">
                            <ng-container *ngIf="auctionTypes | async as auctionTypes">
                                <mat-form-field color="accent">
                                    <mat-label>{{auctionTypeText}}</mat-label>
                                    <mat-select formControlName="auctionType" [disabled]="!isMember">
                                        <mat-option *ngFor="let auctionType of auctionTypes"
                                            [value]="auctionType === 'To be updated'? '' : auctionType">
                                            {{auctionType === 'To be updated'? 'All' : auctionType}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <div class="col-12 col-lg-2">
                            <ng-container *ngIf="banks | async as banks">
                                <mat-form-field color="accent">
                                    <mat-label>{{bankText}}</mat-label>
                                    <mat-select formControlName="bank" [disabled]="!isMember">
                                        <mat-option *ngFor="let bank of banks"
                                            [value]="bank === 'To be updated'? '' : bank">
                                            {{bank === 'To be updated'? 'All' : bank}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12 primary-text-bg">
            <form id="searchProperties" [formGroup]="searchPropertiesForm">
                <div class="container-fluid">
                    <div class="row d-flex align-items-center">
                        <div class="col-12 col-lg-2">
                            <mat-form-field color="accent">
                                <mat-label>Keywords</mat-label>
                                <input formControlName="keywords" matInput />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-2">
                            <ng-container *ngIf="states | async as states">
                                <mat-form-field color="accent">
                                    <mat-label>State</mat-label>
                                    <mat-select formControlName="state">
                                        <mat-option *ngFor="let state of states" [value]="state">
                                            {{state}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <div class="col-12 col-lg-2">
                            <mat-form-field color="accent">
                                <mat-label>Min. Price</mat-label>
                                <input formControlName="minPrice" matInput type="number" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-2">
                            <mat-form-field color="accent">
                                <mat-label>Max. Price</mat-label>
                                <input formControlName="maxPrice" matInput type="number" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-4 p-0">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12 col-lg-4 py-1">
                                        <button mat-raised-button (click)="clearSearch()">Clear</button>
                                    </div>
                                    <div class="col-12 col-lg-4 py-1">
                                        <button mat-raised-button (click)="showAdvanced()">Advanced</button>
                                    </div>
                                    <div class="col-12 col-lg-4 py-1">
                                        <button mat-raised-button class="primary-background-lighter"
                                            (click)="onSearch()">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-1">
                            <button mat-raised-button color="accent" disabled="true">Advanced</button>
                        </div> -->
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>