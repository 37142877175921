
<div *ngIf="!disabled && agentDetails.cardPattern === 'design0'" class="design0">
    <div class="design0-container">
        <div class="design0-greenbar"></div>
        <div class="design0-img-container">
            <img class="card-img" [src]="imgURL">
        </div>
        <div class="design0-text">
            <h1 class="bold-text">
                {{ agentDetails.fullName }}
            </h1>
            <h2>
                {{ agentDetails.email }}
            </h2>
            <h2>
                {{ agentDetails.phoneNumber }}
            </h2>
        </div>
        <div class="design0-icons">
            <span>
                <a class="clickable contact-icons" target="_blank"
                    [href]="'https://api.whatsapp.com/send?text=Hi%20' + agentDetails.username">
                    <fa-icon [icon]="faWhatsapp"></fa-icon>
                </a>
                <a class="clickable contact-icons" target="_blank" [href]="'http://m.me/' + agentDetails.facebookName">
                    <fa-icon [icon]="faFacebookMessenger"></fa-icon>
                </a>
                <mat-icon class="clickable contact-icons mat-icon-align" [inline]="true" [cdkCopyToClipboard]="shareLink"
                    (click)="copied()">content_copy</mat-icon>
            </span>
        </div>
    </div>
</div>

<div *ngIf="!disabled && agentDetails.cardPattern === 'design1'" class="design1">
    <div class="design1-container">
        <div class="design1-top-container">
            <img class="design1-card-img" [src]="imgURL">
            <h1 class="mb-1 italic-text">
                {{ agentDetails.fullName }}
            </h1>
        </div>
        <div class="design1-middle-container">
            <div class="design1-details overflow">
                <h2 class="p-2 m-0 italic-text">
                    <mat-icon class="mat-icon-align" [inline]="true">phone</mat-icon>
                    {{ agentDetails.phoneNumber }}
                </h2>
                <mat-divider class="divider"></mat-divider>
                <h3 class="p-2 m-0 italic-text">
                    <mat-icon class="mat-icon-align" [inline]="true">email</mat-icon>
                    <span class="design1-smaller-text">
                        {{ agentDetails.email }}
                    </span>
                </h3>
            </div>

        </div>
        <div class="design1-icons">
            <span>
                <a class="clickable contact-icons" target="_blank"
                    [href]="'https://wa.me/' + agentDetails.phoneNumber + '?text=Hi%20' + agentDetails.fullName">
                    <fa-icon [icon]="faWhatsapp"></fa-icon>
                </a>
                <a class="clickable contact-icons" target="_blank" [href]="'http://m.me/' + agentDetails.facebookName">
                    <fa-icon [icon]="faFacebookMessenger"></fa-icon>
                </a>
                <mat-icon class="clickable contact-icons mat-icon-align" [inline]="true" [cdkCopyToClipboard]="shareLink"
                    (click)="copied()">content_copy</mat-icon>
            </span>
        </div>
    </div>
</div>

<div *ngIf="!disabled && agentDetails.cardPattern === 'design2'" class="design2">
    <div class="design2-container">
        <div class="design2-top-container">
            <img class="design2-card-img" [src]="imgURL">
            <h1 class="mb-1 italic-text">
                {{ agentDetails.fullName }}
            </h1>
        </div>
        <div class="design2-middle-container">
            <div class="design2-details overflow">
                <h2 class="p-2 m-0 italic-text">
                    <mat-icon class="mat-icon-align" [inline]="true">phone</mat-icon>
                    {{ agentDetails.phoneNumber }}
                </h2>
                <mat-divider class="divider"></mat-divider>
                <h3 class="p-2 m-0 italic-text">
                    <mat-icon class="mat-icon-align" [inline]="true">email</mat-icon>
                    <span class="design1-smaller-text">
                        {{ agentDetails.email }}
                    </span>
                </h3>
            </div>

        </div>
        <div class="design2-icons">
            <span>
                <a class="clickable contact-icons" target="_blank"
                    [href]="'https://wa.me/' + agentDetails.phoneNumber + '?text=Hi%20' + agentDetails.fullName">
                    <fa-icon [icon]="faWhatsapp"></fa-icon>
                </a>
                <a class="clickable contact-icons" target="_blank" [href]="'http://m.me/' + agentDetails.facebookName">
                    <fa-icon [icon]="faFacebookMessenger"></fa-icon>
                </a>
                <mat-icon class="clickable contact-icons mat-icon-align" [inline]="true" [cdkCopyToClipboard]="shareLink"
                    (click)="copied()">content_copy</mat-icon>
            </span>
        </div>
    </div>
</div>