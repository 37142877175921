import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseService } from '../services/base.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-admin-events',
  templateUrl: './admin-events.component.html',
  styleUrls: ['./admin-events.component.scss']
})
export class AdminEventsComponent implements OnInit {
  type = "PHOTO";

  image = [];
  imgURL: any;
  additionalImages = [];
  additionalImgURLs = [];
  newsThumbnail: any;
  newsThumbnailURL: any;

  photoForm = this.fb.group({
    title: this.fb.control('', [Validators.required]),
    description: this.fb.control('', [Validators.required]),
    date: this.fb.control('', [Validators.required]),
  });

  videoForm = this.fb.group({
    title: this.fb.control('', [Validators.required]),
    url: this.fb.control('', [Validators.required]),
    date: this.fb.control('', [Validators.required]),
  });

  newsForm = this.fb.group({
    title: this.fb.control('', [Validators.required]),
    url: this.fb.control('', [Validators.required]),
    date: this.fb.control('', [Validators.required]),
  });

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
  }

  typeChanged(value: string) {
    this.type = value;
  }

  setImage(event: any) {
    this.image[0] = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.image[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }

  setAdditionalImage(event: any) {
    this.additionalImages = [];
    this.additionalImgURLs = [];
    var length = event.target.files.length;
    for (let i = 0; i < length; i++) {
      var image = event.target.files[i];
      this.additionalImages.push(image);
      this.readUrl(image);
    }
  }

  setNewsThumbnail(event: any) {
    this.newsThumbnail = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.newsThumbnail);
    reader.onload = (_event) => {
      this.newsThumbnailURL = reader.result;
    }
  }

  readUrl(file: any) {
    var reader = new FileReader();
    reader.onload = (_event) => {
      this.additionalImgURLs.push(reader.result);
    };
    reader.readAsDataURL(file);
  }

  submitPhoto() {
    this.photoForm.markAllAsTouched();
    if (this.photoForm.valid && this.image) {
      const eventID = uuid.v4();
      var allImages = this.image.concat(this.additionalImages);
      
      let params = this.photoForm.value
      params.date = params.date.getTime();
      params.eventID = eventID;
      params.activated = true;
      params.images = [];
      params.thumbnail = ""

      this.baseService.adminSavePhoto(params, allImages).then(() => {
        window.alert("Event upload successful");
        window.location.reload();
      }).catch(e => {
        window.alert(e);
        window.location.reload();
      });
    }
  }

  submitVideo() {
    this.videoForm.markAllAsTouched();
    if (this.videoForm.valid) {
      const eventID = uuid.v4();
      
      let params = this.videoForm.value;
      params.date = params.date.getTime();
      params.eventID = eventID;
      params.activated = true;

      var videoId = params.url.split('watch?v=')[1];
      var ampersandPosition = videoId.indexOf('&');
      if (ampersandPosition != -1) {
        videoId = videoId.substring(0, ampersandPosition);
      }
      params.videoId = videoId;
      params.thumbnail = 'https://img.youtube.com/vi/' + videoId + '/0.jpg'
      
      this.baseService.adminSaveVideo(params).then(() => {
        window.alert("Video upload successful");
        window.location.reload();
      }).catch(e => {
        window.alert(e);
        window.location.reload();
      });
    }
  }

  submitNews() {
    this.newsForm.markAllAsTouched();
    if (this.newsForm.valid && this.newsThumbnail) {
      const eventID = uuid.v4();
      
      let params = this.newsForm.value
      params.date = params.date.getTime();
      params.eventID = eventID;
      params.activated = true;
      params.thumbnail = ""

      this.baseService.adminSaveNews(params, this.newsThumbnail).then(() => {
        window.alert("News upload successful");
        window.location.reload();
      }).catch(e => {
        window.alert(e);
        window.location.reload();
      });
    }
  }

}
