import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseService } from '../services/base.service';

@Component({
  selector: 'app-buying-form',
  templateUrl: './buying-form.component.html',
  styleUrls: ['./buying-form.component.scss']
})
export class BuyingFormComponent implements OnInit {
  @Input() propertyID = "UNDEFINED";
  // @Input() title = "";
  formTitle = "";
  // @Input() enquireFrom: any;
  @Input() uploadedBy: any;
  buyingEnquiryForm = this.fb.group({
    subject: this.fb.control('', [Validators.required]),
    fullName: this.fb.control('', [Validators.required]),
    // icNumber: this.fb.control('', [Validators.required]),
    // address: this.fb.control('', [Validators.required]),
    email: this.fb.control('', [Validators.required]),
    contactNumber: this.fb.control('', [Validators.required]),
  });

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.propertyID != "UNDEFINED") {
      var params = this.buyingEnquiryForm.value
      if (this.formTitle.toLowerCase().includes("enquiry")) {
        params.subject = "Hi, I am interested in " + this.propertyID;
      } else if (this.formTitle.toLowerCase().includes("register")) {
        params.subject = "Hi, I would like to register to bid for " + this.propertyID;
      }
      this.buyingEnquiryForm.setValue(params);
    }
  }

  @Input() set title(text: string) {
    this.formTitle = text;
    if (this.propertyID != "UNDEFINED") {
      var params = this.buyingEnquiryForm.value
      if (text.toLowerCase().includes("enquiry")) {
        params.subject = "Hi, I am interested in " + this.propertyID;
      } else if (text.toLowerCase().includes("register")) {
        params.subject = "Hi, I would like to register to bid for " + this.propertyID;
      }
      this.buyingEnquiryForm.setValue(params);
    }
  }

  submitEnquiry() {
    this.buyingEnquiryForm.markAllAsTouched();
    if (this.buyingEnquiryForm.valid) {
      var params = this.buyingEnquiryForm.value;
      params.propertyID = this.propertyID;
      var enquireFrom = sessionStorage.getItem("agentName");
      if (enquireFrom) {
        params.viewBy = enquireFrom
      } else if (this.uploadedBy) {
        params.viewBy = this.uploadedBy
      } else {
        params.viewBy = "Hlrealtors"
      }
      this.baseService.submitBuyingForm("buyingEnquiry", params);
    }
  }

}
