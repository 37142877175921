import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventNewsComponent } from './event-news.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [EventNewsComponent],
  imports: [
    CommonModule,
    NgbModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    EventNewsComponent
  ]
})
export class EventNewsModule { }
