import { Component, OnInit } from '@angular/core';
import { BaseService } from '../services/base.service';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Subscription, Observable, pipe } from 'rxjs';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { map, last } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss']
})
export class NewsletterFormComponent implements OnInit {
  states: Observable<any>;
  allStates = [];
  newsletterForm = this.fb.group({
    name: this.fb.control('', [Validators.required]),
    phoneNumber: this.fb.control('', [Validators.required]),
    email: this.fb.control('', [Validators.required]),
    state: this.fb.control('All', [Validators.required]),
  });

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder,
    private remoteConfig: AngularFireRemoteConfig,
    private router: Router,
  ) {
    this.states = this.remoteConfig.strings.states.pipe(
      last(),
      map(o => {
        this.allStates = JSON.parse(o);
        var output = [o.slice(0, 1), '"Any",', o.slice(1)].join('');
        return JSON.parse(output);
      })
    );
  }

  ngOnInit(): void {
  }

  onSubscribe() {
    this.newsletterForm.markAllAsTouched();
    if (this.newsletterForm.valid) {
      const params = this.newsletterForm.value;
      this.baseService.newsletterAll(params).subscribe(all => {
        if (all == 'OK') {
          if (params.state == 'Any') {
            this.allStates.forEach(element => {
              var newParams = params;
              newParams.state = element;
              this.baseService.newsletterState(newParams).subscribe(o => {
                console.log(o);
                if (o == "OK") {
                  window.alert("Subscribed")
                  window.location.reload();
                }
              })
            });
            window.alert("Subscribed")
            window.location.reload();
          } else {
            this.baseService.newsletterState(params).subscribe(o => {
              console.log(o);
              if (o == "OK") {
                window.alert("Subscribed")
                window.location.reload();
              }
            })
          }
        }
      });
    }
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

}
