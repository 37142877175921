import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseService } from '../services/base.service';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { FirstVisitPopupComponent } from '../first-visit-popup/first-visit-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-featured-properties',
  templateUrl: './featured-properties.component.html',
  styleUrls: ['./featured-properties.component.scss']
})
export class FeaturedPropertiesComponent implements OnInit, OnDestroy {
  keyword = ""
  isMember = false;
  notFeatured = true;
  showFeatured = true;
  featuredProperties = [];
  properties = [];
  latestProperties = [];
  // myProperties = [];
  activeFeatured = ["DEFAULT"];
  activeFavourite = ["DEFAULT"];
  pages = 1;
  pageNumber = 0;
  results = 0;
  itemPerPage = 8;
  total: any;
  propertyCounts: any;
  state = "";
  handset = null;
  // showFeatured = false;
  @Input() agentName: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private baseService: BaseService,
    private router: Router,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.showFirstVisitDialog();
    var role = localStorage.getItem('role');
    const username = localStorage.getItem('username');
    var searchParams = JSON.parse(sessionStorage.getItem('searchParams'));
    if (searchParams) {
      this.baseService.searchProperties(searchParams).subscribe(o => {
        if (o) {
          this.setLatestProperty(this.sortByAuctionTime(o));
          var element = document.getElementById("propertiesList");
          setTimeout(() => {
            const yOffset = -65;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }, 1000);
        }
      })
    } else {
      if (role && role.includes('MEMBER')) {
        this.isMember = true;
        const latestSub = this.baseService.getLatestProperties().subscribe(latest => {
          latestSub.unsubscribe();
          this.latestProperties = latest;
          this.setLatestProperty(this.latestProperties);
        })
        const favSub = this.baseService.getFavourite(username).subscribe(o => {
          favSub.unsubscribe();
          const favourite = o.favourite;
          favourite.forEach(propID => {
            this.activeFavourite = [];
            const keywordSub = this.baseService.searchProperties(this.makeParams(propID)).subscribe(fav => {
              keywordSub.unsubscribe();
              if (fav[0]) {
                this.activeFavourite.push(propID);
                this.latestProperties.splice(0, 0, fav[0]);
                this.setLatestProperty(this.latestProperties)
              }
            });
          });
        })
      } else {
        this.baseService.getLatestProperties().subscribe(o => {
          if (o) {
            this.setLatestProperty(o);
          }
        });
      }

      if (this.agentName) {
        // this.showFeatured = true;
        if (role && (role.includes("AGENT") || role.includes("AUCTIONEER"))) {
          this.agentName = username;
          this.baseService.getFeatured(username).subscribe(o => {
            if (o) {
              const featured = o.featured;
              featured.forEach(propID => {
                const keywordSub = this.baseService.searchProperties(this.makeParams(propID)).subscribe(feat => {
                  keywordSub.unsubscribe();
                  if (feat.length != 0) {
                    this.featuredProperties.push(feat[0]);
                  }
                });
              });
            }
          })
          // this.baseService.getMyProperties(username).subscribe(o => {
          //   this.myProperties = o;
          // })
        } else {
          this.baseService.getFeatured(this.agentName).subscribe(o => {
            if (o) {
              const featured = o.featured;
              featured.forEach(propID => {
                const keywordSub = this.baseService.searchProperties(this.makeParams(propID)).subscribe(feat => {
                  keywordSub.unsubscribe();
                  if (feat.length != 0) {
                    this.featuredProperties.push(feat[0]);
                  }
                });
              });
            }
          })
        }
      } else if (role && (role.includes("AGENT") || role.includes("AUCTIONEER"))) {
        // this.showFeatured = true;
        this.agentName = username;
        const getFeat = this.baseService.getFeatured(username).subscribe(o => {
          getFeat.unsubscribe();
          if (o) {
            const featured = o.featured;
            this.activeFeatured = [];
            featured.forEach(propID => {
              const keywordSub = this.baseService.searchProperties(this.makeParams(propID)).subscribe(feat => {
                keywordSub.unsubscribe();
                if (feat.length != 0) {
                  this.featuredProperties.push(feat[0]);
                  this.activeFeatured.push(propID);
                }
              });
            });
          }
        })
        // this.baseService.getMyProperties(username).subscribe(o => {
        //   this.myProperties = o;
        // })
      } else {
        const getFeat = this.baseService.getGeneralFeatured().subscribe(o => {
          getFeat.unsubscribe();
          if (o) {
            const featured = o.featured;
            this.activeFeatured = [];
            featured.forEach(propID => {
              const keywordSub = this.baseService.searchProperties(this.makeParams(propID)).subscribe(feat => {
                keywordSub.unsubscribe();
                if (feat.length != 0) {
                  this.featuredProperties.push(feat[0]);
                  this.activeFeatured.push(propID);
                }
              });
            });
          }
        })
      }
    }

    this.baseService.getPropertyCount().subscribe(o => {
      if (o) {
        var total = 0;
        Object.keys(o).forEach(element => {
          total += o[element];
        });
        this.total = total;
        var sortedArray: any = Object.entries(o).sort((n1, n2) => {
          if (n1[1] < n2[1]) {
            return 1;
          }
          if (n1[1] > n2[1]) {
            return -1;
          }
          return 0;
        });
        this.propertyCounts = sortedArray;
      }
    });
    this.subscriptions.push(this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      if (result.matches) {
        this.handset = true;
      }
    }));
    this.subscriptions.push(this.breakpointObserver.observe([
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.matches) {
        this.handset = false;
      }
    }));
  }

  onSearched(properties: any) {
    sessionStorage.removeItem('pageNumber');
    this.showFeatured = false;
    this.pageNumber = 0;
    this.properties = [];
    this.results = properties.length;
    var result = this.sortByAuctionTime(properties)
    var tempArray = [];
    this.pages = Math.ceil(properties.length / this.itemPerPage);
    for (let i = 0; i < this.pages; i++) {
      for (let j = 0; j < this.itemPerPage; j++) {
        const index = i * this.itemPerPage + j;
        if (result[index]) {
          tempArray.push(result[index]);
        }
      }
      this.properties.push(tempArray);
      tempArray = [];
    }
  }

  showFirstVisitDialog() {
    var visited = sessionStorage.getItem("visited");
    if (visited == null) {
      this.baseService.getPopup(true, false).subscribe(o => {
        if (o[0].href) {
          this.dialog.open(FirstVisitPopupComponent, {
            width: '600px',
          });
        }
      })
      
    }
    sessionStorage.setItem("visited", "true")
  }

  changePage(add: boolean, skip: boolean) {
    window.scroll(0, 0);
    if (skip) {
      if (add) {
        this.pageNumber = this.pages - 1;
      } else {
        this.pageNumber = 0;
      }
    } else {
      if (add) {
        if (this.pageNumber + 1 != this.pages) {
          this.pageNumber++;
        }
      } else {
        if (this.pageNumber + 1 != 1) {
          this.pageNumber--;
        }
      }
    }
    window.sessionStorage.setItem("pageNumber", this.pageNumber.toString());
  }

  setState(state: string) {
    this.state = state;
  }

  setLatestProperty(properties: any) {
    const pageNum = window.sessionStorage.getItem("pageNumber");
    if (pageNum) {
      this.pageNumber = +pageNum;
    } else {
      this.pageNumber = 0;
    }
    this.properties = [];
    this.results = properties.length;
    var result = properties;
    var tempArray = [];
    this.pages = Math.ceil(properties.length / this.itemPerPage);
    for (let i = 0; i < this.pages; i++) {
      for (let j = 0; j < this.itemPerPage; j++) {
        const index = i * this.itemPerPage + j;
        if (result[index]) {
          tempArray.push(result[index]);
        }
      }
      this.properties.push(tempArray);
      tempArray = [];
    }

  }

  keywordSearch() {
    this.router.navigateByUrl("/property-search/" + this.keyword);
  }

  sortByAuctionTime(toSort): any {
    var sorted: any = toSort.sort((n1, n2) => {
      if (n1.auctionTime > n2.auctionTime) {
        return 1;
      }
      if (n1.auctionTime < n2.auctionTime) {
        return -1;
      }
      return 0;
    });
    return sorted;
  }

  makeParams(propertyID: string): any {
    var params = {
      collection: "properties",
      state: null,
      keywords: [propertyID.toLowerCase()],
      minPrice: null,
      maxPrice: null,
      active: false,
      privateTreaty: false,
      pendingAuction: false,
      soonReauction: false,
      expired: false,
      propertyType: null,
      minArea: null,
      maxArea: null,
      minDate: null,
      maxDate: null,
      auctionType: null,
      bank: null,
    }
    return params;
  }

  ngOnDestroy(): void {
    const role = localStorage.getItem('role');
    const username = localStorage.getItem('username');
    if (role && role.includes('MEMBER')) {
      if (this.activeFavourite[0] != "DEFAULT") {
        const favouriteParams = {
          username: username,
          favourite: this.activeFavourite
        }
        this.baseService.toggleFavourite(favouriteParams);
      }
      if (role.includes("AGENT")) {
        if (this.activeFeatured[0] != "DEFAULT") {
          const featuredParams = {
            username: username,
            featured: this.activeFeatured
          }
          this.baseService.toggleFeatured(featuredParams);
        }
      }
    }
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

}
