import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'personalised/:username', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'upload-property', loadChildren: () => import('./upload-property/upload-property.module').then(m => m.UploadPropertyModule) },
  { path: 'property-card', loadChildren: () => import('./property-card/property-card.module').then(m => m.PropertyCardModule) },
  { path: 'featured-properties', loadChildren: () => import('./featured-properties/featured-properties.module').then(m => m.FeaturedPropertiesModule) },
  { path: 'property-detail/:propertyID', loadChildren: () => import('./property-detail/property-detail.module').then(m => m.PropertyDetailModule) },
  { path: 'property-detail/:propertyID/personalised/:username', loadChildren: () => import('./property-detail/property-detail.module').then(m => m.PropertyDetailModule) },
  { path: 'update-property/:propertyID', loadChildren: () => import('./update-property/update-property.module').then(m => m.UpdatePropertyModule) },
  { path: 'add-agent', loadChildren: () => import('./add-agent/add-agent.module').then(m => m.AddAgentModule) },
  { path: 'add-agent/:referral', loadChildren: () => import('./add-agent/add-agent.module').then(m => m.AddAgentModule) },
  { path: 'agent-list', loadChildren: () => import('./agent-list/agent-list.module').then(m => m.AgentListModule) },
  { path: 'user-profile', loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule) },
  { path: 'privacy-policy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'admin-console', loadChildren: () => import('./admin-console/admin-console.module').then(m => m.AdminConsoleModule) },
  { path: 'tnc', loadChildren: () => import('./tnc/tnc.module').then(m => m.TncModule) },
  { path: 'about-us', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'private-auction-buy', loadChildren: () => import('./private-auction-buy/private-auction-buy.module').then(m => m.PrivateAuctionBuyModule) },
  { path: 'private-auction-sell', loadChildren: () => import('./private-auction-sell/private-auction-sell.module').then(m => m.PrivateAuctionSellModule) },
  { path: 'private-auction-sell/:selectedIndex', loadChildren: () => import('./private-auction-sell/private-auction-sell.module').then(m => m.PrivateAuctionSellModule) },
  { path: 'management-console', loadChildren: () => import('./management-console/management-console.module').then(m => m.ManagementConsoleModule) },
  { path: 'management-console/:formID', loadChildren: () => import('./management-console/management-console.module').then(m => m.ManagementConsoleModule) },
  { path: 'management-console/:formID/:propertyID', loadChildren: () => import('./management-console/management-console.module').then(m => m.ManagementConsoleModule) },
  { path: 'faqs', loadChildren: () => import('./faqs/faqs.module').then(m => m.FaqsModule) },
  { path: 'newsletter-unsubscribe', loadChildren: () => import('./newsletter-unsubscribe/newsletter-unsubscribe.module').then(m => m.NewsletterUnsubscribeModule) },
  { path: 'our-services', loadChildren: () => import('./our-services/our-services.module').then(m => m.OurServicesModule) },
  { path: 'our-services/:section', loadChildren: () => import('./our-services/our-services.module').then(m => m.OurServicesModule) },
  { path: 'sign-up/:role', loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpModule) },
  { path: 'subscription', loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule) },
  { path: 'admin-subscription', loadChildren: () => import('./admin-subscription/admin-subscription.module').then(m => m.AdminSubscriptionModule) },
  { path: 'calendar-dialog', loadChildren: () => import('./calendar-dialog/calendar-dialog.module').then(m => m.CalendarDialogModule) },
  { path: 'property-search', loadChildren: () => import('./property-search/property-search.module').then(m => m.PropertySearchModule) },
  { path: 'property-search/:keyword', loadChildren: () => import('./property-search/property-search.module').then(m => m.PropertySearchModule) },
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'events', loadChildren: () => import('./events/events.module').then(m => m.EventsModule) },
  { path: 'tutorial', loadChildren: () => import('./tutorial/tutorial.module').then(m => m.TutorialModule) },
  { path: 'event-details/:eventID', loadChildren: () => import('./event-details/event-details.module').then(m => m.EventDetailsModule) },
  // { path: '**', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
