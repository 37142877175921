<mat-card class="mat-elevation-z8">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 px-3">
                <div class="mat-h1">
                    {{ formTitle }}
                </div>
            </div>
            <div class="col-12">
                <form id="buyingEnquiry" [formGroup]="buyingEnquiryForm">
                    <div class="container-fluid">
                        <div class="row d-flex align-items-center">
                            <mat-form-field color="accent">
                                <mat-label>Subject</mat-label>
                                <input formControlName="subject" matInput />
                                <mat-error>Please fill in</mat-error>
                            </mat-form-field>
                            <mat-form-field color="accent">
                                <mat-label>Full name</mat-label>
                                <input formControlName="fullName" matInput />
                                <mat-error>Please fill in</mat-error>
                            </mat-form-field>
                            <!-- <mat-form-field color="accent">
                                <mat-label>NRIC number</mat-label>
                                <input formControlName="icNumber" matInput type="number"/>
                                <mat-error>Please fill in</mat-error>
                            </mat-form-field>
                            <mat-form-field color="accent">
                                <mat-label>Address</mat-label>
                                <input formControlName="address" matInput />
                                <mat-error>Please fill in</mat-error>
                            </mat-form-field> -->
                            <mat-form-field color="accent">
                                <mat-label>E-mail</mat-label>
                                <input formControlName="email" matInput type="email" email />
                                <mat-error>Please fill in</mat-error>
                            </mat-form-field>
                            <mat-form-field color="accent">
                                <mat-label>Contact number</mat-label>
                                <input formControlName="contactNumber" matInput />
                                <mat-error>Please fill in</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <div class="d-flex flex-row-reverse">
                    <button mat-raised-button color="accent" (click)="submitEnquiry()" form="buyingEnquiry">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-card>