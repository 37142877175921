import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BaseService } from '../services/base.service';

@Component({
  selector: 'app-pending-approvals',
  templateUrl: './pending-approvals.component.html',
  styleUrls: ['./pending-approvals.component.scss']
})
export class PendingApprovalsComponent implements OnInit {
  @Input() details: any;
  reasonForm = this.fb.group({
    reason: this.fb.control(''),
  });
  constructor(
    private baseService: BaseService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  adminEditSubscription(statusChange: string) {
    if (statusChange == "APPROVED") {
      this.baseService.adminEditAgentSubscription(this.details.username, this.details.subscriptionID, statusChange);
    } else if (statusChange == "REJECTED") {
      const reason = this.reasonForm.value.reason;
      if (reason != '') {
        this.baseService.adminEditAgentSubscription(this.details.username, this.details.subscriptionID, statusChange, reason);
      } else {
        window.alert("Please supply a rejection reason")
      }
    }
  }

}
