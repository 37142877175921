import { Component, OnInit } from '@angular/core';
import { BaseService } from '../services/base.service';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = this.fb.group({
    email: this.fb.control('', [Validators.required]),
    password: this.fb.control('', [Validators.required, Validators.minLength(8)]),
  });
  forgotPasswordLink = window.location.origin + "/#/forgot-password/";
  
  constructor(
    private baseService: BaseService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  onLogin() {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      const params = this.loginForm.value;
      this.baseService.signIn(params.email, params.password);
    }
  }

  redirect(url: string) {
    window.location.href = url;
  }

  getErrorString(field: AbstractControl) {
    return field.hasError('required') ? 'This field is required' : '';
  }

}
