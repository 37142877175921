<div class="container-fluid my-5">
    <div class="row">
        <div class="col-12">
            <div class="bold-text mat-h1 mb-3">
                Videos
            </div>
        </div>
        <div class="col-12">
            <div class="row justify-content-center mb-3">
                <div *ngFor="let element of paginatedVideo[page-1]" class="col-12 col-md-4">
                    <div class="clickable">
                        <div class="img-container">
                            <a [href]="element.url" target="_blank">
                                <img class="images" [src]="element.thumbnail">
                            </a>
                        </div>
                        <div class="date-text mt-4">
                            {{ element.date | date: 'mediumDate' }}
                        </div>
                        <div class="mat-h2 bold-text center-text mt-3 mb-2">
                            {{ element.title }}
                        </div>
                        <div class="d-flex justify-content-center">
                            <a class="read-more" [href]="element.url" target="_blank">Watch Video <mat-icon>arrow_forward</mat-icon></a>
                        </div>
                    </div>
                    <div *ngIf="isAdmin">
                        <ng-container *ngIf="element.activated; else deactivated">
                            <button mat-raised-button color="warn"
                                (click)="changeStatus(element.eventID, element.activated)">Deactivate</button>
                        </ng-container>
                        <ng-template #deactivated>
                            <button mat-raised-button color="accent"
                                (click)="changeStatus(element.eventID, element.activated)">Activate</button>
                        </ng-template>
                    </div>
                </div>
            </div>
            <ngb-pagination class="d-flex justify-content-end" [pageSize]="pageSize" [collectionSize]="video.length"
                [(page)]="page" [maxSize]="maxSize" [boundaryLinks]="true"></ngb-pagination>
        </div>
    </div>
</div>