<div class="top-nav">
    <app-top-nav></app-top-nav>
</div>
<div class="router-content mb-2">
    <router-outlet></router-outlet>
</div>
<div>
    <app-footer></app-footer>
</div>
<div class="whatsapp">
    <a target="_blank" href="https://wa.me/60174059268?text=Hi%20Homebid">
        <img class="icon" src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png">
    </a>
</div>