<div class="background">
    <div class="container py-5 px-3">
        <div class="row px-3">
            <div class="col-12 col-md-5 pr-5">
                <p class="mat-h2 my-3 primary-text headers">Subscribe Newsletter</p>
                <app-newsletter-form></app-newsletter-form>
            </div>
            <div class="col-12 col-md-4 divider-left pl-5">
                <p class="mat-h2 my-3 primary-text headers">Contact Us</p>
                <p class="mat-body primary-text mb-2">8-2, Persiaran Bayan Indah<br>11900 Bayan Lepas, Pulau Pinang</p>
                <p class="mat-body primary-text mb-0">Tel: +604 637 1276</p>
                <p class="mat-body primary-text mb-0">Customer service: 017 405 9268</p>
                <a href="mailto:info@homeland.my" class="mat-body primary-text email">
                    <p class="mb-3">Email: info@homeland.my</p>
                </a>
                <div class="d-flex justify-content-start">
                    <div class="mr-3">
                        <a target="_blank" href="https://wa.me/60174059268?text=Hi%Homebid">
                            <fa-icon [icon]="faWhatsapp" size="2x"></fa-icon>
                        </a>
                    </div>
                    <div class="mr-3">
                        <a target="_blank" href="http://m.me/homelandhousing/">
                            <fa-icon [icon]="faFacebookMessenger" size="2x"></fa-icon>
                        </a>
                    </div>
                    <div class="mr-3">
                        <a target="_blank" href="https://www.facebook.com/homelandhousing/">
                            <fa-icon [icon]="faFacebook" size="2x"></fa-icon>
                        </a>
                    </div>
                    <div class="mr-3">
                        <a target="_blank" href="https://www.instagram.com/homelandgroup.my/">
                            <fa-icon [icon]="faInstagram" size="2x"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3 divider-left pl-5">
                <p class="mat-h2 my-3 primary-text headers">Company</p>
                <p class="clickable" (click)="navigate('/privacy-policy')">Privacy Policy</p>
                <p class="clickable" (click)="navigate('/tnc')">Terms and Conditions</p>
            </div>
        </div>
    </div>

</div>