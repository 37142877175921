<mat-card class="content">
    <div class="mat-h1 bold-text mb-4">
        Auction on <span *ngIf="event">{{ event.date | date : 'mediumDate' }}</span>
    </div>
    <div *ngIf="allBanks" class="container-fluid banks">
        <div class="row">
            <div *ngFor="let bank of allBanks | keyvalue" class="col-12 p-0 mb-3">
                <div class="card p-3">
                    <div class="d-flex justify-content-between">
                        <div class="mat-h2 bold-text mb-1">{{bank.key}}</div>
                        <button mat-flat-button class="bold-text" (click)="getCalendarProperties(bank.key)">
                            View Listing<mat-icon>arrow_forward</mat-icon>
                        </button>
                    </div>
                    <hr class="my-2">
                    <div class="row">
                        <div class="col-6 col-md-3 bold-text">Total unit(s)</div>
                        <div class="col-6 col-md-3">{{bank.value.length}}</div>
                        <div class="col-6 col-md-3 bold-text">Auction Date</div>
                        <div class="col-6 col-md-3">
                            {{bank.value[0].start | date : 'mediumDate'}}
                            <span *ngIf="bank.value[0].start != getStart(bank.value[0].start)">
                                ({{bank.value[0].start | date : 'shortTime'}})
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>